@import "../../../assets/styles/mixins.scss";

.modal-position-absolute {
    &__modal-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);
        z-index: 5;
    }

    &__description-modal {
        background-color: white;
        max-width: 582px;
        min-height: 293px;
        border-radius: 20px;
        z-index: 5;
        padding: 40px 50px 40px 50px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        box-sizing: border-box;
        margin: 20px 15px;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    }
}

.two-btn {
    display: flex;
    gap: 20px;
}