@import "./variables.scss";

@mixin flex($direction: row, $justify: space-between, $align: center, $gap: 0, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
  flex-wrap: $wrap;
}

@mixin modal() {
  width: 100%;
  height: 100vh !important;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 5;
}

@mixin modalContent($width: unset, $height: 40px, $bg: #ffffff, $radius: 4px, $padding: 0,) {
  width: $width;
  height: $height;
  background: $bg;
  border-radius: $radius;
  padding: $padding;
}

@mixin text($color: #000000, $size: 16px, $weight: 400, $line: normal, $padding: 0, $text-align: unset) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
  line-height: $line;
  padding: $padding;
  text-align: $text-align;
}

@mixin button($bg: #ffffff,
  $radius: 4px,
  $border: none,
  $width: unset,
  $height: 40px,
  $btn-text-color: white,
  $hover-bg: darken($bg, 10%),
  $hover-text-color: $btn-text-color,
  $hover-border: $border,
  $hover-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)) {
  background: $bg;
  border-radius: $radius;
  border: $border;
  width: $width;
  height: $height;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  color: $btn-text-color;

  &:hover {
    background-color: $hover-bg;
    color: $hover-text-color;
    border-color: $hover-border;
    box-shadow: $hover-shadow;
  }
}

@mixin hover-fade {
  transform: scale(1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

@mixin media-mobile {
  @media screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin media-smartphone {
  @media screen and (max-width: $breakpoint-smartphone) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin media-laptop {
  @media screen and (max-width: $breakpoint-laptop) {
    @content;
  }
}

@mixin media-large-laptop {
  @media screen and (max-width: $breakpoint-large-laptop) {
    @content;
  }
}

@mixin media-desktop {
  @media screen and (max-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin media-large-desktop {
  @media screen and (min-width: $breakpoint-large-desktop) {
    @content;
  }
}

$base-font-size: 1rem;

@function responsive-font-size($size, $vwSize) {
  @return calc(#{$base-font-size} + #{$size} * #{$vwSize}rem);
}

@mixin flexColumn($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin responsive-title-style($default-size, $default-weight, $mobile-size, $mobile-weight, $mobile-color) {
  font-size: $default-size;
  font-weight: $default-weight;

  @media screen and (max-width: 768px) {
    font-size: $mobile-size;
    font-weight: $mobile-weight;
    color: $mobile-color;
  }
}