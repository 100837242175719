.user-contact-info {
  &--desktop {
    width: 290px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 40px;
    transform: translate(0px, -40px);
    // display: none;

    @media (max-width: 1276px) {
      position: unset;
      display: flex;
      justify-content: flex-end;
      transform: unset;
    }

    @media (max-width: 768px) {
      transform: unset;
      width: 100%;
    }
  }

  @media (max-width: 1276px) {
    width: 100%;

    &--mobile {
      display: none;
    }
  }
}

.contact-info-wrapper {
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
}

.contact-info {
  width: 1276px;
  min-height: 370px;
  // padding: 20px;
  background: white;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: nowrap;

  @media (max-width: 768px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__commission {
    font-weight: 600;
    padding-bottom: 16px;
  }

  &__balance-title {
    font-weight: 600;
  }

  .manager-user__page-status {
    margin-top: 10px;
  }

  .option__bayer {
    margin-top: 8px;
  }

  .option__web-master {
    margin-top: 8px;
  }

  .pointer {
    cursor: pointer;
  }

  &__title {
    color: black;
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
  }

  .user-type {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__option {
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;

      .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #8f97a9;

        &.active {
          background: #ff1053;
        }
      }

      .label {
        color: black;
        font-size: 14px;
        font-family: "Manrope", sans-serif;
        font-weight: 400;
      }
    }
  }

  .contact-info__select {
    width: 239px !important;
  }

  .add-advertising-system {
    color: #6c6ea0;
    font-size: 14px;
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    margin-top: 8px;
  }

  .balance-section {
    width: 100%;
    max-width: 290px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    height: 100%;

    @media (max-width: 1276px) {
      order: -1;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    .balance-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background: #6c6ea0;
      border-radius: 12px;
      padding: 30px 20px;
      color: white;
      flex-grow: 1;

      .balance-item {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        gap: 8px;

        .amount {
          font-size: 14px;
          font-family: "Manrope", sans-serif;
          font-weight: 700;
        }

        .currency {
          color: #999ac5;
          font-size: 14px;
          font-family: "Manrope", sans-serif;
          font-weight: 400;
        }
      }

      .user-contact-info--mobile {
        height: 100%;
        margin-top: auto;
        /* Прижимаем кнопку к низу */
      }
    }
  }
}

.save-button {
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 8px 16px;
  background: #d2d5da;
  border-radius: 8px;
  color: black;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  cursor: pointer;
  border: none;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    background-color: #b3b3b3;
  }
}

.contact-info.manager-user__contact-info {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 1276px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 20px;
  }
}

.contact-info__toggle {
  width: 100%;
  box-sizing: border-box;
  display: none;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  color: #8f97a9;
  font-weight: 600;
}

.contact-info__content.hidden {
  display: none;
}

// .user-contact-info--mobile {
//   margin-top: auto;
//   /* Прижимаем к низу */
// }

@media (max-width: 768px) {
  .contact-info__toggle {
    display: flex;
    justify-content: center;
    background-color: white;
  }
}