.manager-page {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    min-height: 70vh;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      flex-wrap: unset;
    }
  }

  &__user-info {
    width: 100%;
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      img {
        display: none;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__save-btn,
  &__back-btn {
    width: 244px;
    border-radius: 8px;
    height: 39px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__save-btn {
    background-color: #ff1053;
    color: white;
    border: none;
  }

  &__back-btn {
    background-color: transparent;
    border: 2px solid #8f97a9;
    color: #ff1053;
  }

  &__input {
    width: 100% !important;
  }

  &__copy-btn {
    cursor: pointer;
    position: absolute;
    top: 45px;
    right: 10px;
  }

  &__input-phone {
    width: calc(100% - 40px) !important;
  }

  &__status {
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;

    p {
      cursor: pointer;
    }
  }
}

.user-info {
  &__avatar {
    width: 48px;
  }

  &__checked-circle {
    width: 32px;
    height: 32px;
  }

  &__email {
    color: black;
    font-size: 24px;
    font-family: "Manrope";
    font-weight: 600;
    word-wrap: break-word;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__status {
    width: 110px;
    height: 20px;
    padding: 2px 16px;
    background: #b9f6b5;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #176c10;

    &--inactive {
      background: #d2d5da;
      color: #4d576f;
    }
  }

  .status__text {
    color: #176c10;
    font-size: 12px;
    font-family: "Manrope";
    font-weight: 600;
    word-wrap: break-word;

    &--inactive {
      color: #d2d5da !important;
    }
  }
}

.contact-info {
  flex: 1;
  min-width: 300px;
  // margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.user-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.add-user-btn {
  margin-top: 10px;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
}

.manager-class-name {
  background-color: inherit;

  @media screen and (max-width: 768px) {
    padding: auto 0;
  }
}

.manager-page__save-btn.default {
  background-color: #d2d5da;
  cursor: not-allowed;
  pointer-events: all;
}

.manager-page__save-btn.default:hover {
  background-color: #d2d5da;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}
