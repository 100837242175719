.user-type-section {
  &__title {
    font-weight: 600;
  }


}

.label, .option__web-master {
  cursor: pointer;
}