@import "../../../../assets/styles/mixins.scss";
@import "../../../../assets/styles/variables.scss";

.qrcode {
  background-color: $defaultWhiteColor;
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 520px;
  box-sizing: border-box;

  &__subtitle {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 350px;
    }
  }

  h1 {
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 30px;
  }

  &__input {
    width: 100% !important;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 20px;
    // border: 1px solid #ccc;
    border-radius: 4px;
  }

  &__add-btn {
    min-width: 96px;
    height: 42px;
    background-color: #ff1053;
    color: white;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 0 10px;
  }

  &--center-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.qrcode-container {
  display: flex;
  justify-content: center;
  background: white;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.secret-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #f5f5f5;
  border-radius: 9px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.secret-number {
  color: black;
  font-size: 15px;
  font-family: "Manrope";
  font-weight: 700;
  word-wrap: break-word;
}

.two-factor-systems {
  display: flex;
  gap: 27px;
  justify-content: flex-start;
  align-items: flex-start;

  div {
    // text-align: center;
    display: flex;
    flex-direction: column;
    gap: 11px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  img {
    box-shadow: 0px 6.8px 20.4px rgba(0, 0, 0, 0.15);
    border-radius: 13.62px;
    overflow: hidden;
    width: 64px;
    height: 64px;
  }

  p {
    font-size: 13px;
  }
}
