@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.offer-contract {
  .list {
    counter-reset: item;
    list-style-type: none;

    .list__sublist {
      list-style-type: none;
      padding: 0;

      li:first-child {
        margin-top: 24px;
      }
    }
  }

  .list__item {
    counter-increment: item;

    &--title {
      color: #000;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 12px;
    }
  }

  .list__item::before {
    content: counters(item, ".") ". ";
  }

  .list__sublist {
    counter-reset: subitem;

    li:last-child {
      margin-bottom: 24px;
    }
  }


  ul {
    transform: translateX(1em);
    list-style-type: disc;
    color: black;

    li::marker {
      display: inline-block;
      width: 0.75em; 
      height: 0.75em;
      background-color: black; 
      border-radius: 50%;
    }

  }

  .list__subitem {
    counter-increment: subitem;
    margin-top: 24px;
  }

  .list__subitem::before {
    content: counters(item, ".") "." counter(subitem) " ";
    font-weight: normal;
  }
}

.offer-contract * {
  padding: 0;
  box-sizing: border-box;
}

.offer-contract-box {
  display: flex;
  flex-direction: column;

  &__item {
    font-size: 14px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media (min-width: 1024px) {
  .offer-contract-box__list {
    li {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .offer-contract * {
    margin-left: auto;
    margin-right: 0;
  }
}