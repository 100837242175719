@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

.user-list {
  min-width: 300px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 14px;
  min-height: 205px;
  font-family: "Manrope", sans-serif;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 40px;
  }

  p {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .table-container {
    overflow-y: auto;
    max-height: 204px;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    padding: 14px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cfd2da;
      border-radius: 3px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    .user-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;

      &:last-child {
        border-bottom: none;
      }

      .user-info {
        display: flex;
        align-items: center;
        gap: 5px;

        .avatar {
          width: 24px;
          height: 24px;
          background: #f5f5f5;
          border-radius: 9999px;
          position: relative;

          .avatar-inner {
            width: 12.86px;
            height: 15.43px;
            background: #d2d5da;
            position: absolute;
            left: 2.57px;
            top: 1.29px;
          }
        }

        .user-email {
          width: 205px;
          color: black;
          font-size: 14px;
          font-family: "Manrope", sans-serif;
          font-weight: 400;
          word-wrap: break-word;
        }
      }

      .action-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        overflow: hidden;

        .checkbox {
          width: 24px;
          height: 24px;
          appearance: none;
          background-color: #fff;
          border: 1px solid #8f97a9;
          border-radius: 3px;
          position: relative;
          cursor: pointer;

          &:checked {
            background-color: #ff1053;
            border: none;

            &::before {
              content: "✔";
              color: white;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .add-user-btn {
    color: #6c6ea0;
    font-size: 14px;
    font-weight: 600;
    border: none;
    text-align: start;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      color: #e50950;
    }
  }

  .delete-user-btn {
    @extend .add-user-btn;
    text-align: end;
  }
}

.delete-user-btn {
  color: #cfd2da !important;
}

.selected-delete-user-btn {
  color: #6c6ea0 !important;
}

.delete-user-manager-box {
  display: flex;
}

.user-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-list-title {
  margin: 0;
}

.select-all-btn {
  color: #6c6ea0;
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    color: #e50950;
  }
}

.delete-user__box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.save-user__box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.jam-plus {
  margin-top: 10px;
}

.user-list__title {
  font-size: 32px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

.user-list__input-title {
  color: #8f97a9;
  font-size: 14px;
}

.user-list__modal {
  position: absolute;
  background-color: white;
  width: 100%;
  max-width: 590px;
  border-radius: 20px;
  z-index: 5;
  padding: 40px 50px 40px 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    position: unset;
    gap: 16px;
    padding: 30px;
  }
}

.no-users-found-modal {
  color: #6c6ea0;
}

.add-user-btn,
.delete-user-btn,
.select-all-btn,
.table-container {
  font-family: "Manrope", sans-serif;
}

.manager-page__save-btn,
.manager-page__back-btn {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.manager-page__save-btn:hover {
  background-color: #d80c45;
}

.manager-page__back-btn:hover {
  border-color: #ff1053;
}

.checkbox-container {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .checkmark {
      background-color: #ff1053;

      .custom-check-icon {
        display: block;
        position: absolute;
        // top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .checkmark {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    outline: 1px solid #8f97a9;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .custom-check-icon {
      display: none;
    }
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.delete-user-btn.managers-dlt-btn {
  background-color: transparent !important;
  color: rgba(108, 110, 160, 1) !important;
}

.delete-user-btn.managers-dlt-btn:hover {
  box-shadow: none;
}