svg {
  color: #6c6ea0;
  &:hover {
    color: hsl(0, 0%, 60%);
    fill: hsl(0, 0%, 60%);
  }
}

.messenger-select {
    width: 90px;

    .custom-react-select__control {
        height: 52px;
        background-color: #f0f0f0;
        border: none;
        border-radius: 8px;
        // padding: 0 10px;

        @media (max-width: 768px) {
            width: 90px;
        }
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }
}

.custom-react-select__input {
  display: none;
}

.custom-react-select__indicator {
  cursor: pointer;
}

.manager-select {
  width: 100% !important;

  .custom-react-select__control {
    width: 100%;
    height: 52px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 90px;
    }
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }
}

.manager-select-wrapper {
  width: 100%;

  .custom-react-select__control {
    width: 100%;
    height: 52px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }
}

.full-width-select-wrapper {
  width: 100%;
}
