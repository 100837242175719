.faq-add-qeustion-box {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
}

.fr-box.fr-basic.fr-top {
  margin-top: 20px;

  .fr-wrapper,
  .fr-wrapper.show-placeholder {
    margin-top: 20px;
    background-color: #F5F5F5 !important;
    border-radius: 8px;
    border: none !important;
    height: 400px;
    max-width: 815px;
  }

  .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
    background-color: #F5F5F5;
    border-radius: 8px;
    border: none;
    max-width: 815px;
  }

  .fr-second-toolbar {
    display: none;
  }
}

.add-advetising-btn-create.save-added-question {
  width: 123px;
}

.question-description {
  margin-top: 12px;
}

.faq-question-add-box {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 20px;
}

.demo-editor.rdw-editor-main {
  min-height: 400px;
}

.rdw-editor-toolbar {
  background-color: #f0f0f0;
  margin-top: 12px;
  border-radius: 6px;
  max-width: 800px;
  box-sizing: border-box;
}

.public-DraftEditor-content {
  background-color: #f0f0f0;
  margin-top: 10px;
  border-radius: 6px;
  max-width: 800px;
  padding: 10px 15px;
  overflow: scroll;
  min-height: 400px;
  box-sizing: border-box;
}

.rdw-option-wrapper {
  background-color: #f0f0f0;
}

.rdw-dropdown-selectedtext,
.rdw-dropdown-selectedtext {
  background-color: #f0f0f0;
}

.delete-faq-question {
  cursor: pointer;
}

.add-question-modal {
  position: absolute;
  background-color: white;
  max-width: 460px;
  min-height: 293px;
  border-radius: 20px;
  z-index: 5;
  padding: 40px 50px 40px 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.red-button-white-text {
  width: 100%;
  max-width: 200px;
  height: 39px;
  background-color: #ff1053;
  color: white;
  border-radius: 8px;
  border: none;
}

.demo-editor .public-DraftEditorPlaceholder-root {
  color: #8f97a9; // #8f97a9;
  z-index: 99;
  padding: 20px;
}

.faq-absolute-layer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
}