@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.table-container {
  width: 100%;
  font-family: Manrope;


  .add-balance-bonus-box {
    display: flex;
    gap: 10px;
  }

  &__put-money-button {
    width: 100%;
    max-width: 123px;
    height: 27px;
    background-color: #ff1053;
    border: none;
    color: white;
    border-radius: 8px;

    div {
      font-weight: 400;
    }

    &:disabled {
      background-color: #d3d3d3;
      border-color: #d3d3d3;
      cursor: not-allowed;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    align-items: center;
    margin-bottom: 10px;
  }

  &__search-container {
    // width: 100%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__full-width-search-container {
    width: 100%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .filter-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    font-family: Manrope;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &__select {
      width: 100%;
      min-height: 44px;
      padding: 5px 10px;
      font-size: 15px;
      border: none;
      border-radius: 8px;
      background-color: #f0f0f0;
      box-sizing: border-box;
      color: #8f97a9;
      /* Цвет текста плейсхолдера */
    }

    &__input {
      @extend .filter-container__select;
      min-width: 300px;

      @media screen and (max-width: 768px) {
        min-width: 100%;
      }
    }

    &__input:hover {
      background: #e0e0e0;
    }

    .filter-group {
      display: flex;
      flex-direction: column;
      min-width: 285px;

      @media (max-width: 768px) {
        width: 100%;
        min-width: unset;
      }
    }
  }

  .custom-table__label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #8f97a9;
  }

  .table-add-balance-modal-btn {
    @include button($width: 164px, $height: 39px, $bg: #FF1053, $border: none, $hover-text-color: #FFFFFF, $radius: 8px, );
    color: #FFFFFF;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .available-balance-foot-label {
    @include text($size: 14px, $weight: 400, $color: #D2D5DA);
    margin-top: 10px;
  }

  .status-balance-office-box {
    justify-content: flex-start;
    gap: 20px;
  }

  .balance-footer-txt {
    display: flex;
    justify-content: space-between;
  }

  .table-add-balance-btn-box {
    display: flex;
    justify-content: end;
    width: 100%;

    @media (max-width: 768px) {
      padding-top: 32px;
      justify-content: center;
    }
  }

  .custom-table__user-cell {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #c0c0c0 #fafafa;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: 2px solid #f0f0f0;
    }

    table {
      width: 100%;
      min-width: 900px;
      border-collapse: collapse;
      font-size: 14px;

      thead {
        background-color: #f9f9f9;
      }

      th,
      td {
        height: 100%;
        white-space: nowrap;
        padding: 8px 4px;
        min-height: 24px;
        text-align: left;
        // font-size: 12px;
        // color: #8f97a9;
      }

      th {
        font-weight: normal;
        font-size: 14px;
        color: #8f97a9;
      }

      tr>td,
      tr>th {
        min-height: 36px;
        line-height: 36px;
        /* Ensure the content is centered vertically */
        vertical-align: middle;
        /* Vertically center the content */
      }

      tbody tr:nth-child(odd) {
        background-color: white;
      }

      tbody tr:nth-child(even) {
        background-color: transparent;
      }

      tbody tr:hover {
        background-color: #f1f1f1;
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        padding: 0;
        border-radius: 0;
        /* Убедитесь, что родительский td не имеет padding и border-radius */

        div {
          overflow: hidden;
          background-color: inherit;
          border-radius: 17px;
          padding: 3px 0px;
          height: 20px; // фиксированная высота 20px
          line-height: 20px; // высота строки также 20px
          min-width: 110px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          padding-left: 15px;
          padding-right: 15px;
        }

        &.status-open div {
          background-color: #b9f6b5;
          color: #176c10;
        }

        &.status-closed div {
          background-color: #D2D5DA;
          color: #4D576F;

        }

        &.status-under-consideration div {
          color: #757720;
          background: #fdff8b;
        }

        &.status-active div {
          color: #176c10;
          background: #b9f6b5;
        }

        &.status-rejected div {
          color: #d80027;
          background: #ffcfd7;
        }

        &.status-suspended div {
          color: #4d576f;
          background: #d2d5da;
        }

        &.status-inactive div {
          color: #4d576f;
          background: #d2d5da;
        }

        &.status-recharge-completed div {
          margin-top: 13px;
          background-color: #b9f6b5; // зеленый фон
          color: #176c10; // темно-зеленый текст
        }

        &.status-recharge-processing div {
          margin-top: 13px;
          background-color: #ffd89e; // оранжевый фон
          color: #b97b20; // коричневый текст
        }

        &.status-client div {
          margin-top: 13px;
          background-color: #E5F1FF; // оранжевый фон
          color: #778AA1; // коричневый текст
          min-width: fit-content;
          border-radius: 50px;
          text-align: center;
          text-transform: capitalize;
        }

        &.status-new div {
          margin-top: 13px;
          background-color: #B9F6B5; // оранжевый фон
          color: #176C10; // коричневый текст
          min-width: fit-content;
          border-radius: 50px;
          text-align: center;
          text-transform: capitalize;
        }

        &.status-notactive div {
          margin-top: 13px;
          background-color: #D2D5DA; // оранжевый фон
          color: #4D576F; // коричневый текст
          min-width: fit-content;
          border-radius: 50px;
          text-align: center;
          text-transform: capitalize;
        }

        &.status-hot div {
          margin-top: 13px;
          background-color: #FFC98B; // оранжевый фон
          color: #856137; // коричневый текст
          min-width: fit-content;
          border-radius: 50px;
          text-align: center;
          text-transform: capitalize;
        }

        &.status-spam div {
          margin-top: 13px;
          background-color: #FFCFD7; // оранжевый фон
          color: #D80027; // коричневый текст
          min-width: fit-content;
          border-radius: 50px;
          text-align: center;
          text-transform: capitalize;
        }

        &.status-recharge-rejected div {
          margin-top: 13px;
          background-color: #ffcfd7; // розовый фон
          color: #d80027; // красный текст
        }

        &.status-recharge-awaiting div {
          margin-top: 13px;
          background-color: #fff599; // желтый фон
          color: #d0a800; // темно-желтый текст
        }
      }

      .status-open {
        display: flex;
        min-width: 97px;
        // padding: 2px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        color: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .status-open {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
      }

      .status-closed {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
      }

      .status-under-consideration {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
      }

      .status-active {
        display: flex;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        margin-top: 8px;
      }

      .status-rejected {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
      }

      .status-suspended {
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;
      }

      .status-text {
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
      }

      .icon {
        font-size: 18px;
      }
    }
  }
}

.min-height-full {
  min-height: 70vh;
}

.CiSettings {
  cursor: pointer;
}

.email-cell {
  cursor: pointer;
  color: #6c6ea0;
  font-weight: 600;

  &:hover {
    color: #ff1053;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  .page-button,
  button {
    width: 32px;
    height: 32px;
    margin: 0 2.5px;
    padding: 5px 10px;
    border: 2px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fafafa;
    cursor: pointer;
    font-weight: bold;
    color: #8f97a9;

    &.active {
      background-color: white;
      color: #ff1053;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }

  span {
    margin: 0 5px;
    padding: 5px 10px;
  }
}

.status-inactive {
  color: #4d576f;

  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.input-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .filter-container__input {
    padding-right: 30px;
    color: #8f97a9;
    width: 100%;

    &::placeholder {
      color: #8f97a9; // Цвет текста плейсхолдера
    }
  }

  .filter-container__input-left {
    padding-left: 40px;
    padding-right: unset;
    // color: #8f97a9;

    // &::placeholder {
    //   color: #8f97a9; // Цвет текста плейсхолдера
    // }
  }

  .input-icon {
    position: absolute;
    right: 10px;
    pointer-events: none; // Сделайте так, чтобы иконка не перехватывала события мыши
  }

  .input-icon-left {
    position: absolute;
    left: 10px;
    pointer-events: none; // Сделайте так, чтобы иконка не перехватывала события мыши
  }
}

.no-results-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100px;
  width: 100%;
}

.add-balance-bonus {
  justify-content: space-between !important;
  align-items: center;
}

.no-results {
  width: 100%;
  text-align: center;
  padding: 20px;
  // font-size: 16px;
  // color: #666;
  color: #8F97A9;
  font-family: Manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hide-filters-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // gap: 10px; // промежуток между кнопкой и иконкой

  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 10px; // отступ снизу для кнопки и иконки
  }
}

.hide-filters-button {
  width: unset;
  display: none;
  background: none;
  border: none;
  color: #8f97a9;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;

  @media (max-width: 768px) {
    display: block;
  }
}

.table-container__filters.hidden {
  display: none;
}

.page-button.active {
  pointer-events: none; // Отключаем кликабельность активного элемента
  background-color: #ff1053; // Добавляем активное состояние
  color: white;
  // border-color: #ff1053;
}

.pagination span {
  display: inline-block;
  padding: 0;
  color: #8f97a9;
}

.pagination button:disabled {
  cursor: unset; // Убираем pointer и ставим соответствующий курсор для отключенных кнопок
  // opacity: 0.5; // Можно добавить легкую прозрачность для disabled кнопок
}

.status-select-transparent {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  color: inherit;
  padding: 4px 8px;
  border-radius: 4px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  option {
    padding: 4px 8px; // Добавляем внутренние отступы внутри каждого option
    line-height: 1.5; // Увеличиваем высоту строки, чтобы создать видимый отступ
    margin-bottom: 4px; // Отступ между элементами option
  }

  option:last-child {
    margin-bottom: 0; // Убираем отступ для последнего элемента
  }
}

.status-select-transparent option {
  color: black;
  padding: 4px 8px;
  /* Устанавливаем базовый цвет */
}

.status-select-transparent option::before {
  content: '';
  /* Псевдоэлемент перед опцией */
  display: block;
  margin-bottom: 4px;
  /* Отступ между опциями */
}

.status-select-transparent option[data-color="green"] {
  color: #176C10;
}

.status-select-transparent option[data-color="orange"] {
  color: #8C5300;
}

.status-select-transparent option[data-color="red"] {
  color: #D80027;
}

.status-select-transparent option[data-color="yellow"] {
  color: #757720;
}

.option-style {
  padding: 4px !important;
}

.icon {
  cursor: pointer;
  pointer-events: auto;
  display: flex;
  align-items: center;
  height: 100%;
}

.page-button {
  display: flex;
  /* Используем flexbox для удобного выравнивания */
  align-items: center;
  /* Центрируем по вертикали */
  justify-content: center;
  /* Центрируем по горизонтали */
  width: 40px;
  /* Опционально: ширина кнопки */
  height: 40px;
  /* Опционально: высота кнопки */
  border-radius: 5px;
  /* Опционально: для закругленных углов */
  // font-size: 16px;
  /* Размер шрифта для кнопки */
  text-align: center;
  /* Центрируем текст внутри кнопки */
  // line-height: 40px;
  /* Делаем текст по вертикали центрированным, если не используем flexbox */
  cursor: pointer;
}

.page-button.active {
  background-color: #f00;
  /* Цвет фона для активной кнопки */
  color: #fff;
  /* Цвет текста для активной кнопки */
}

.pagination {
  display: flex;
  /* Flexbox для общего контейнера пагинации */
  justify-content: center;
  /* Центрируем все кнопки */
  align-items: center;
  /* Выравниваем кнопки по центру вертикально */
}

// .ticket-closed {
//   background-color: #f5f5f5;
//   color: #8f97a9;

//   td {
//     color: #8f97a9;
//   }

//   &:hover {
//     background-color: #e0e0e0;
//   }
// }

.display-finance-btn {
  width: 100%;
  display: flex;
  justify-content: end;
}

.dont-show-table-input {
  margin-top: 15px;
}

.not-show-searchby {
  gap: 0;
}

.table-action-head {
  max-width: 5px;
}