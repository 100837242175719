@import '../../../assets/styles/mixins.scss';

.registration {
    background-color: #f9f9f9;
    padding: 0 20px;

    &--min-height {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__column {
        width: 100%;
        display: flex;
        align-items: stretch;
    }

    &__main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        margin-bottom: 45px;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: center;

            .registration__main {
                align-items: flex-start;
            }
        }
    }

    &__left-side {
        display: none;
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
        height: 100%;

        img {
            width: 100%;
        }

        @media (min-width: 768px) {
            display: flex;
            justify-content: stretch;
            align-items: stretch;
            background-color: white;
            align-items: center;
            width: 60%;
            height: 100%;
            
        }
    }

    &__header__left-text {
        @include text($size: 28px, $weight: 700, $line: 1rem);
        padding-top: 0;
    }

    &__footer {
        @include text($size: 13px, $weight: 400, $color: #8F97A9);
        padding: 0 20px;
    }

    &__footer,
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1300px;

        button {
            width: 100%;
            height: 40px;
            background-color: transparent;
            border: 2px solid #8F97A9;
            outline: none;
            border-radius: 8px;
            color: #FF1053;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        &__column {
            width: 100%;
            display: flex;
        }

    }

    .register-header-btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-weight: 600;
        padding: 0 16px;

        button {
            width: 114px;
            height: 40px;
            background-color: transparent;
            border: 2px solid #8F97A9;
            outline: none;
            border-radius: 8px;
            color: #FF1053;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
    
    .gap-none {
        gap: 0px;
    }

    &-header-btn-icon {
        display: flex;
        align-items: center;
        gap: 5px;
        max-width: 180px;
        height: 40px;
        background-color: transparent;
        border: 2px solid #8F97A9;
        outline: none;
        border-radius: 8px;
        color: #FF1053;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @media (max-width: 768px) {
            max-width: 100%;
        }
    }

    &__signin-section {
        display: none;
    }

    @media (max-width: 768px) {
        &__signin-section {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__signin-section {
        padding-bottom: 30px;
    }
}

.header-right-side-container {
    display: flex;
    gap: 20px;
    align-items: center;
    position: relative;

    &--display-none {
        display: none !important;
    }

    @media (max-width: 990px) {
        &--column {
            flex-direction: column;
            align-items: start;
            gap: 8px;
        }
    }
}

