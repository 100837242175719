.app-loader {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 99999;
  top: 0;
  left: 0;

  opacity: 0.8;
}
.app-loader {
  height: 100%;
  padding-top: 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}
.overflow-hidden {
  height: calc(100vh - 92px);
  overflow: hidden;
}
#app.loading + .lds-grid {
  display: inline-block;
}
.lds-grid {
  position: relative;
  width: 160px;
  height: 160px;
}
.lds-grid div {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f82c91;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 16px;
  left: 16px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 16px;
  left: 64px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 16px;
  left: 112px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 64px;
  left: 16px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 64px;
  left: 64px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 64px;
  left: 112px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 112px;
  left: 16px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 112px;
  left: 64px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 112px;
  left: 112px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
