@import "../../../assets/styles/mixins.scss";

.modal-container {
    width: 100%;
    height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 5;
    box-sizing: border-box;
    // margin: 0 15px;
}

.two-btn {
    display: flex;
    gap: 20px;
}