
@import '../../../assets/styles/mixins.scss';

.success-registration-box {
    width: 100%;
    max-width: 644px;
    height: 466px;
    @include flex( $direction: column, $justify: center, );
    padding: 0;

    @media (max-width: 768px) {
        height: 341px;
    }

}