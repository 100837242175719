@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.manager-profile {
  &__container {
    @include flex($align: center, $justify: center);
    gap: 26px;
  }

  &__box {
    width: 100%;
    max-width: 628px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    background-color: $defaultWhiteColor;
    border-radius: 20px;
    margin-bottom: 20px;

    .label-container {
      margin-bottom: 8px;
    }

    .register-label {
      margin: 0;
    }

    .input-wrapper {
      border: none;
    }

    .register-input {
      color: #000;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0 24px;
      border: none;
    }

    .register-input:focus {
      background: #fbfbfb;
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }

    .register-input::placeholder {
      color: #000;
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .register-input:focus::placeholder {
      color: #8f97a9;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .phone-box {
      gap: 1px;

      .label-container {
        display: none;
      }
    }

    .phone-select {
      width: 106px !important;
      display: flex;
      justify-content: space-between !important;
      padding: 0 8px;
      box-sizing: border-box;
    }

    .select-box {
      .label-container {
        display: none;
      }
    }

    .two-fatctor-settings__box {
      & > p {
        margin: 0;
        color: #8f97a9;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .connect-two-auth {
        color: #ff1053;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__btn {
    width: 184px;
  }
  &__btn-box {
    @include flex($align: center, $justify: center);
  }
  &__title {
    width: 100%;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
}

.phone-container {
  width: 100%;
}

.two-fatctor-settings__box {
  display: flex;
  width: 100%;
  color: #8f97a9;
  align-items: center;
  justify-content: space-between;
}
