@import "../../../assets/styles/mixins.scss";

.approve-registration {
    @include text($size: 20px, $weight: 400, $text-align: left, $line: auto);

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.approve-registration-box {
    max-width: 628px;
    height: 353px;
    @include flex($gap: 20px, $direction: column, $justify: start);
    margin-bottom: 30px;

    @media (max-width: 768px) {
        height: 328px;
    }

    &--container {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__absolute-layer {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 100;
            background-color: rgba(255, 255, 255, 0.5);
            top: 0;
            left: 0;
            backdrop-filter: blur(5px);
            /* Adds the blur effect */
        }
    }
}

.approve-registration-input {
    width: 61px;
    height: 100px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: none;
    outline: none;
    @include text($size: 36px, $weight: 500, $text-align: center);

    @media (max-width: 768px) {
        width: 40px;
        height: 60px;
    }
}

.approve-registration-input-section {
    display: flex;
    width: 100%;
    max-width: 412px;
    justify-content: space-between;
}

.approve-registration-title {
    text-align: left;
    margin: 0;
}

.repeat-code {
    cursor: pointer;
}

.check-email {
    font-size: 32px;
    font-weight: 400;
    color: #000000;
}

.code-sended {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.add-balance__modal-container__main-box.approve-code-modal {
    min-height: 175px;
    max-width: 400px;
    display: flex;
    justify-content: space-around;
    margin: 0 10px 180px;
    min-width: auto;
    padding: 20px;
}

.user-approve {
    gap: 10px;
}

.code-close-icon-img {
    margin-top: 20px;
    margin-left: 90px;
    cursor: pointer;
}

.approve-user-email {
    margin: 0;
    color: #000000;
    font-weight: 400;
    font-size: 20px;
}

.continue-approve {
    margin-top: -15px;
}

.approve-email-title {
    text-align: center;
}

.add-balance__modal-container.approve-code-modal-container {
    backdrop-filter: blur(10px);
}