@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.manager-users__box {
  @include flex($gap: 20px, $align: center, $justify: start);
}

.users-title {
  @include responsive-title-style(20px, 500, 14px, 700, #ff1053);
}
