@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.auth-two-factor {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $defaultWhiteColor;
    border-radius: 20px;
    width: 100%;

    img {
        width: 80%;
        height: auto;
    }

    @media screen and (max-width: 1023px) {
        display: none;
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
    }

    &__column {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: stretch;
    }

    &__left-side {
        display: none;
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
        height: 100%;

        img {
            width: 100%;
        }

        @media (min-width: 768px) {
            display: flex;
            justify-content: stretch;
            align-items: stretch;
            background-color: white;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }

    &__right {
        background-color: $defaultWhiteColor;
        border-radius: 20px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        width: 60%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}

.auth-two-factor__right p {
    font-size: 14px;
    font-family: "Manrope", sans-serif;
    word-wrap: break-word;
    color: black;
    margin-bottom: 20px;
}

.auth-two-factor__right h1 {
    font-size: 28px;
    font-weight: 700;
    font-family: "Manrope", sans-serif;
    color: black;
}

@media screen and (min-width: 1024px) {
    .auth-two-factor {
        width: 60%;
        height: 100%;
        left: 81px;
        top: 80px;
        position: absolute;
    }

    .auth-two-factor img {
        width: 60%;
        left: 27px;
        top: 107px;
        position: absolute;
    }

}

@media screen and (max-width: 1023px) {
    .auth-two-factor__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
    }

    .auth-two-factor {
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        position: static;
        padding: 20px;
    }

    .auth-two-factor img {
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        position: static;
    }

    .auth-two-factor__right {
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        position: static;
        padding: 20px;
    }
}
