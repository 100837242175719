@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.advertising-systems-office {
    &__back-button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 8px;
        cursor: pointer;
        color: #6c6ea0;
        font-weight: 600;
    }
}

.add-ads-balance-modal {
    @include modal();

    .empty-balance-office {
        margin-bottom: 20px;
    }

    .available-balance {
        display: flex;
        gap: 20px;
        @include text($size: 20px, $weight: 400, $color: #000000);

        @media (max-width: 400px) {
            flex-direction: column;

            p {
                margin: 0;
            }
        }
    }

    .available-balance-sum {
        @include text($size: 20px, $weight: 400, $color: #000000);
    }

    .available-balance-foot-label {
        @include text($size: 14px, $weight: 400, $color: #D2D5DA);
        margin-top: 10px;
    }

    &__title {
        // @include text($size: 32px, $weight: 400, );
        font-size: 32px;
        font-weight: 400;
        margin: unset;
        //   line-height: $line;
    }

    .cancel-add-balance-modal-btn {
        @include button($width: 244px, $height: 39px, $bg: transparent, $border: 1px solid #FF1053, $hover-text-color: #FF1053, $radius: 8px, );
        color: #FF1053;
    }

    .add-balance-modal-btn {
        @include button($width: 244px, $height: 39px, $bg: #FF1053, $border: none, $hover-text-color: $defaultWhiteColor, $radius: 8px, );
        color: $defaultWhiteColor;
    }

    .add-balance__modal-container__main-box__btns {
        width: 100%;
        justify-content: space-between;
    }

    .create-ticket-txt {
        @include text($size: 32px, $weight: 400, );
    }

    .create-ticket-email {
        margin-top: 0;
        margin-bottom: 40px;
        font-weight: 600;
    }

    .create-ticket-email-title {
        margin-bottom: 30px;
    }

    .create-ticket-btn {
        @include button($width: 244px, $height: 39px, $bg: transparent, $border: 1px solid #8F97A9, $hover-text-color: #FF1053, $radius: 8px, );
        color: #FF1053;
    }
}

// .create-ticket-modal {
//     height: 269px !important;
// }

.purple-text {
    color: #6C6EA0;
}

.add-ads-full-width {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.add-ads-balance-modal-content {
    @include modalContent($width: 100%, $height: unset, $radius: 20px, $padding: 30px, );
    box-sizing: border-box;
    max-width: 550px;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    margin: 0 20px;

    // @media (max-width: 790px) {
    //     width: 400px
    // }

    // @media (max-width: 450px) {
    //     width: 300px
    // }
}

.ads-currency {
    @media (max-width: 790px) {
        right: 10px !important;
    }
}

.advertising-systems {
    max-width: $maxWidth;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.advertising-page-sort {
    display: flex;
    gap: 20px;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 70px;
}

.networks-box {
    display: flex;
    width: 100%;
}

.networks-item {
    width: -webkit-fill-available;
    height: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    padding-top: 30px;
    @include flex($gap: 20px, $direction: row, $justify: space-between);
    padding: 40px;

    &--center {
        display: flex;
        justify-content: center;

        @media (max-width: 990px) {
            button {
                width: 100%;
            }
        }
    }

    &__hidden-function {
        display: none;
        width: 100%;
        font-size: 13px;
        padding-bottom: 16px;
        color: #8f97a9;
        font-weight: 600;

        div,
        svg {
            cursor: pointer;
        }

        @media (max-width: 990px) {
            display: flex;
            gap: 8px;
            justify-content: center;
        }
    }

    &__right-side {
        width: 501px;

        @media (max-width: 990px) {
            width: 100%;
            max-width: 501px;
        }
    }

    &--left {
        justify-content: start;
        display: flex;

        @media (max-width: 990px) {
            width: 100%;
            flex-direction: column;
        }
    }

    @media (max-width: 990px) {
        flex-direction: column;
        padding: 20px;
    }
}

.networks-right-side-box {
    width: 100%;
    box-sizing: border-box;
    min-height: 145px;
    background-color: #eaf8ff;
    color: #42a6d5;
    padding: 20px;
    border-radius: 8px;
    @include flex($gap: 10px, $direction: column, $align: start);

    @media (max-width: 990px) {
        width: 100%;
    }

    p {
        margin: 0;
    }
}

.networks-left-side-box {
    height: 100%;
    margin-top: 0;

    &__child {
        @media (max-width: 790px) {
           display: flex;
           gap: 10px;
           align-items: center;
        }
    }
}

.advertising-systems-header-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #d2d5da !important;
    border-radius: 8px !important;
    color: #d2d5da !important;
    text-align: center;
    @include button($width: 204px, $height: 38px, $bg: white);

    @media (max-width: 768px) {
        display: none !important;
    }
}

.networks-right-side-text {
    @include text($weight: 600, $color: #42a6d5);
}

.networks-right-side-span {
    @include text($size: 14px, $weight: 400, $color: #42a6d5);
}

.networks-right-side-btn {
    @include button($height: 38px, $bg: transparent, $border: 1px solid #d8d9da);
    @include text($size: 12px, $weight: 600, $text-align: center, $color: #d9d9d9);
    padding: 0 10px;
    line-height: normal;

    @media (min-width: 990px) {
        width: 100%;

        font-size: 14px;
    }
}

.networks-right-side-btns-box {
    @include flex($gap: 5px, $justify: center);
    margin-top: 20px;


    @media (max-width: 990px) {
        button {
            width: 100%;
        }
    }
}

.networks-right-side-btn:hover {
    color: #d9d9d9;
}

.advertising-btn {
    @include button($width: 204px, $height: 38px, $bg: #ff1053);
    @include text($size: 14px, $weight: 600, $text-align: center, $color: white);
    margin-top: 40px;

    @media (max-width: 990px) {
        &--mobile-hidden {
            display: none;
        }
    }

    @media (min-width: 990px) {
        &--desktop-hidden {
            display: none;
        }
    }
}

.networks-description {
    width: 352px;
    @include text($size: 13px, $weight: 400, $text-align: left, $color: $defaultGray);
}

.btn-disabled {
    @include button($width: 352px, $height: 42px, $bg: #f0f0f0);
    color: #8f97a9;
}

.networks-title {
    @include text($size: 16px, $weight: 600, $text-align: left);
    margin-top: 10px;
}

.networks-status {
    @include text($size: 13px, $weight: 600, $text-align: left, $color: $defaultGray);
    margin-top: 20px;

    span {
        color: black;
        font-weight: 700;
    }
}

.default-description {
    margin-top: 20px;
    @include text($size: 13px, $weight: 600, $text-align: left, $color: $defaultGray);
}

.networks-box .main-input {
    @include text($size: 13px, $weight: 600, $text-align: left, $color: $defaultGray);
}

.advertising-systems-page {
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;

    &__back-button {
        width: 100%;
        display: none;
        justify-content: start;
        padding-bottom: 25px;
        color: #6c6ea0;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        @media (max-width: 990px) {
            display: flex;
        }
    }
}

.main-footer-class {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &--column-left {
        width: 100%;
        align-items: center; //self-start;

        @media (max-width: 768px) {
            flex-direction: column-reverse;
            gap: 20px;
            align-items: self-start;
        }
    }
}

.advertising-systems-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.advertising-systems-page--footer-bottom {
    height: 100%;
}

.advertising-systems-page__footer {
    position: absolute;
    bottom: 0;

    p {
        padding: 0 20px;
    }

    @media (max-width: 990px) {
        position: static;
        display: flex;
        flex-direction: column-reverse;
        align-items: start !important;

        p {
            padding: 0;
        }
    }
}

.header-absolute-background-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: white;
    z-index: 0;
}

.description-modal {
    position: absolute;
    background-color: white;
    max-width: 582px;
    min-height: 293px;
    border-radius: 20px;
    z-index: 5;
    padding: 40px 50px 40px 50px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 15px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.no-cabinet * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.no-cabinet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 83px;

    &__description-link {
        cursor: pointer;
    }

    &__title {
        color: #8f97a9;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 14px;
    }

    &__description {
        color: #00100b;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 24px;

        span {
            color: #ff1053;
        }
    }

    &__button {
        width: max-content;
        padding: 8px 24px;
        margin-bottom: 0;
    }
}

.description-modal button {
    width: max-content;
    padding: 8px 16px;
}

.rules-modal-box * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.rules-modal-box {
    margin: 0;
    padding: 40px;
    gap: 32px;
    align-items: center;

    h1 {
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 32px;
    }

    p {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    ul {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style-position: inside;
        padding-left: 30px;
        margin-bottom: 12px;
    }
}

.facebook-ads-box * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.facebook-ads-box {
    margin: 0;
    padding: 40px;
    gap: 32px;
    align-items: center;
    max-width: max-content;

    h1 {
        margin-bottom: 32px;
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    ol {
        display: flex;
        flex-direction: column;
        gap: 12px;
        list-style-position: inside;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.description-box * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.description-box {
    max-width: 660px;
    gap: 32px;
    padding: 40px;
    margin: 0;
    align-items: center;
}

.description {
    @include flexColumn(12px);
}

.description__header {
    margin-bottom: 20px;
}

.description h1 {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.description__content span {
    color: #ff1053;
}

.description h2 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.description__platforms {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.description ul {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
    list-style-position: inside;

    li {
        &::marker {
            font-size: 10px;
        }
    }
}

.description__parameters {
    @include flexColumn(8px);
}

.description ol {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    li {
        list-style-position: inside;
    }
}

.description__formats {
    @include flexColumn(8px);
}

@include media-mobile {}

@include media-smartphone {
    .description-box {
        padding: 30px;
        gap: 16px;
        // height: 100%;
        justify-content: space-around;
        margin: 0 15px;
    }

    .description__header {
        margin-bottom: 16px;
    }

    .description {
        overflow-y: scroll;
        max-height: 70dvh;
        padding-right: 20px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #d9d9d9;
        }
    }

    .rules-modal-box {
        gap: 16px;
        padding: 30px;

        h1 {
            font-size: 24px;
            margin-bottom: 16px;
        }
    }

    .facebook-ads-box {
        gap: 16px;
        padding: 30px;
        margin: 0 15px;

        h1 {
            font-size: 24px;
            margin-bottom: 16px;
        }

    }
}

.ads-offices {
    .table-container {
        margin-top: 5px;

        table {
            thead tr th:first-child {
                padding-left: 10px;
            }

            tbody tr td:first-child {
                padding-left: 10px;
            }

            thead {
                tr {
                    th {
                        color: #8f97a9;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            tbody {
                tr {
                    height: 47px;

                    td {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

@include media-tablet {
    .ads-offices {
        .table-container {
            table {
                // thead {
                //     display: none;
                // }

                tbody {
                    tr {
                        // display: flex;
                        flex-wrap: wrap;

                        td {
                            flex: 1 1 100%;

                            &:nth-child(1) {
                                order: 4;
                                flex: 1 1 33.33%;
                            }

                            &:nth-child(2) {
                                order: 1;
                                flex: 1 1 50%;
                            }

                            &:nth-child(3) {
                                order: 3;
                                flex: 1 1 33.33%;
                            }

                            &:nth-child(4) {
                                order: 5;
                                flex: 1 1 33.33%;
                            }

                            &:nth-child(5) {
                                display: none;
                            }

                            &:nth-child(6) {
                                order: 2;
                                flex: 1 1 50%;
                            }

                            &:nth-child(7) {
                                order: 6;
                                flex: 1 1 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-mobile {
    .ads-offices {
        .table-container {
            table {
                thead {
                    display: none;
                }

                tbody {
                    tr {
                        display: flex;
                        flex-wrap: wrap;

                        td {
                            flex: 1 1 100%;

                            &:nth-child(1) {
                                order: 4;
                                flex: 1 1 100%;
                            }

                            &:nth-child(2) {
                                order: 1;
                                flex: 1 1 100%;
                            }

                            &:nth-child(3) {
                                order: 3;
                                flex: 1 1 100%;
                            }

                            &:nth-child(4) {
                                order: 5;
                                flex: 1 1 50%;
                            }

                            &:nth-child(5) {
                                display: none;
                            }

                            &:nth-child(6) {
                                order: 2;
                                flex: 1 1 50%;
                            }

                            &:nth-child(7) {
                                order: 6;
                                flex: 1 1 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ads-available-sum {
    @include text($size: 14px, $weight: 500, $text-align: center, $color: #8F97A9);
}

.available-balance-main {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 40px;

    @media (max-width: 990px) {
        padding-left: 0;
    }


}

.available-balance-sum-main {
    @include text($size: 36px, $weight: 700, $color: #8F97A9);
}

.ads-available-sum {
    margin: 0;
}

.available-balance-sum-main-black {
    color: #00100B;
    margin-right: -5px;
}

.description-ads:hover,
.description-ads {
    @include text($size: 14px, $weight: 700, $color: #6C6EA0, $text-align: center);
    border: 1px solid #D8D9DA;
    border-radius: 8px;

    @media (max-width: 990px) {
        @include text($size: 12px, $weight: 700, $color: #6C6EA0, $text-align: center);
    }
}

// .modalPositionAbsolute {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     backdrop-filter: blur(8px);
// }

.advertising-btn-third {
    border-radius: 8px;
}

.advertising-systems-office__back-button {
    margin-bottom: 30px;
}

.mobile-ads-system {
    @media (max-width: 990px) {
        border-radius: 8px;
    }
}

.ads-offices {
    .first-data-classname {
        padding-right: 60px !important;
    }
}

.advertising-btn--mobile-hidden {
    border-radius: 8px;
}
