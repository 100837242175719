.App {
  text-align: center;
}

body,
html {
  height: 100%;
  /* Necessary to set to make footer stick to the bottom */
  margin: 0;
  /* Remove default margin */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes input-focus {
  0% {
    box-shadow: 0 0 0 0 rgba(143, 151, 169, 0.25);
    outline: none;
  }

  100% {
    box-shadow: 0 0 3px 1px rgba(143, 151, 169, 0.25);
    outline: 2px solid #8f97a9;
  }
}

input {
  transition: box-shadow 0.3s ease;
  color: #8f97a9;
  border: 1px solid blue;
}

input:focus {
  animation: input-focus 0.8s forwards;
  outline: 2px solid #8f97a9;
}

input:-webkit-autofill {
  background-color: #f0f0f0 !important;
}

input:autofill {
  background-color: #f0f0f0 !important;
}