.main-input {
    width: 100%;
    max-width: 1276px;
    height: 44px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;
    outline: none;
    padding-left: 45px !important;
    max-width: 1300px;
    color: #6c6ea0;


  &::placeholder {
    color: #6C6EA0;
    font-size: 14px;
    font-weight: 400;
  }
}

.main-input-box {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    position: relative;
    margin-top: 19px;
    margin-bottom: 28px;
    width: 100%;
}

.main-input-icon-box {
    position: absolute;
    left: 10px;
}
