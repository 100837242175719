@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.button {
  font-weight: 500;
  font-size: 15px;

  &--align-height {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &--right-padding {
    padding-right: 7px;
  }

  &--red-border {
    border: 2px solid #ff1053 !important;
  }

  &:disabled,
  &.disabled {
    color: #808080 !important; // Серый цвет для текста
    background-color: #e0e0e0 !important; // Серый цвет фона
    border-color: #c0c0c0 !important; // Серый цвет границы
    // cursor: not-allowed !important; // Указатель для disabled
  }
}

.button-hover {
  @include hover-fade;
}

.btn-add-balance {
  padding: 0;
  color: #FF1053;
}