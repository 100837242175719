.search-input-container {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  .search-input {
    flex-grow: 1;
    padding: 8px 12px;
    padding-right: 40px; // space for icon
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    min-height: 44px;
    padding: 5px 10px;
    font-size: 15px;
    border: none;
    border-radius: 8px;
    background-color: #f0f0f0;
    box-sizing: border-box;
    color: #8f97a9;

    &:focus {
      outline: none;
      border-color: blue;
    }
  }

  .search-input-icon {
    position: absolute;
    right: 10px;
    pointer-events: none;
  }
}

.search-input-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
}
