.faq-current-qeustion {
  display: flex;
  flex-direction: column;

  // justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 20px;
  // min-height: 700px;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: unset;
  }

  &__description {
    max-width: 900px;
    margin-top: 50px;
  }
}

.faq-current-question-actions {
  width: 100%;
  text-align: end;
  margin-top: 12px;
}

.faq-current-qeustion-wrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  img {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.faq-current-qeustion-content {
  font-size: 14px;
}

.faq-current-qeustion-title {
  font-size: 24px;
  font-weight: 600;
}

.faq-current-qeustion-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.faq-question-back {
  color: #6C6EA0;
  font-weight: 500;
  cursor: pointer;
}