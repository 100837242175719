.relative {
  position: relative;
}

.phone-box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1px;
}

.phone-img {
  cursor: pointer;
}

.phone-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.phone-dropdown-item {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
}

.phone-input {
  flex: 1;
  padding: 8px;
  border-radius: 8px;
}

.manager-phone-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.copy-success {
  position: absolute;
  top: -30px;
  right: 0;
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 2s;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.manager-phone__img {
  width: 100%;
  max-width: 90px;
  height: 54px;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;

  @media (max-width: 768px) {
    width: unset !important;
    min-width: 90px;
    padding: 0 10px;
  }
}