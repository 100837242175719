@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.router-list {
    margin-top: 14px;
    margin-bottom: 30px;
    @include flex($gap: 24px, $justify: flex-start);
    @include text($size: 14px, $weight: 600, $text-align: center, $color: $black);

    @media (max-width: 768px) {
        display: none;
    }

    div {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        div {
            display: none;
        }

        div:first-child {
            display: block;
        }
    }
}

.active-route {
    color: $defaultRedColor;
}

.hide-on-mobile {
    @media (max-width: 768px) {
        display: none;
    }
}
