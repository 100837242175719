@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.bg-finance-history-calendar {
  background-color: #F0F0F0;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.manager-home {
  &__wrapper {
    min-height: 80vh;
    margin-top: 30px;
    display: block;

    @media screen and (max-width: 768px) {
      margin-top: 0px;
    }
  }

  &__buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  &__cabinetes-label {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      font-weight: 700;
      color: #ff1053;
    }
  }

  &__btn-create {
    width: 100%;
    max-width: 200px;
    height: 39px;
    background-color: #ff1053;
    color: white;
    border-radius: 8px;
    border: none;

    @media (max-width: 768px) {
      max-width: 106px;
    }
  }

  &__manager-ads-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button-back {
    width: 100%;
    max-width: 200px;
    height: 39px;
    border: 1px solid #d2d5da;
    background-color: transparent;
    color: #6c6ea0;
    border-radius: 8px;
    font-weight: 600;
    display: block;

    &--mobile-hidden {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &--desktop-hidden {
      margin-bottom: 12px;
      display: none;

      @media (max-width: 769px) {
        display: block;
      }
    }

    @media (min-width: 768px) {
      width: 226px;
    }
  }
}

.manager-label {
  @include text($size: 14px, $weight: 400, $text-align: left, $color: $defaultGray);
  margin: 0 0 10px 0;
}

.percent-img {
  position: absolute;
  right: 20px;
  top: 20px;
}

.percent-box {
  position: relative;
}

.office-status__box {
  @include flex($gap: 5px, $align: center, $justify: start);
}

.advetising-cabinetes-label {
  width: 100%;
  @include responsive-title-style(20px, 500, 14px, 700, #ff1053);
}

.ads-system-btn-back {
  width: 195px;
  height: 39px;
  max-width: 195px;
  color: $levandaColor;
}

.manager-offices-box {
  display: flex;
  gap: 30px;
}

.create-new-office {
  height: 896px;
  max-width: 594px;
  width: 594px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 30px;
    height: 100%;
  }
}

.offices-box {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.new-office-txt-modal {
  @include text($size: 32px, $weight: 400, $text-align: left);
  margin-bottom: 0;
  margin-top: 0;
}

.individual-comission {
  margin: 0;
  @include text($size: 16px, $weight: 600, $text-align: left);
}

.office-status-txt {
  margin: 0;
}

.add-office-status-labels {
  @include text($size: 14px, $weight: 400, $text-align: left);
}

.add-office-modal-cancel,
.add-office-modal-save {
  width: 244px;
  max-width: 100%;
}

.add-office-modal-save {
  background-color: #d2d5da;
}

.editInputChanged {
  background-color: #ff1053;
}

.newOfficeInputChanged {
  background-color: #ff1053;
}

.add-office-ads-select {
  width: 100%;
}

.percent-box,
.percent-modal-input {
  width: 179px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.manager-home__modal-container {
  width: 100%;
  height: 100%;
  // height: 100vh !important;
  position: absolute;
  top: 100px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // backdrop-filter: blur(5px);
  z-index: 5;
}

.manager-change-label {
  @include text($size: 14px, $weight: 400, $text-align: left, $color: #ff1053);
  margin: 0 0 10px 0;
  cursor: pointer;
}

.manager-change-label-box {
  display: flex;
  justify-content: space-between;
  // cursor: pointer;
}

.manager-search-label-box {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 17px;
  right: 20px;
}

.manager-home-users-table {
  position: absolute;
  overflow-y: auto;
  max-height: 204px;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 14px;
  box-sizing: border-box;
  background-color: white;
  max-width: 514px;
  z-index: 100;
  margin-top: 20px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfd2da;
    border-radius: 3px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      .avatar {
        width: 24px;
        height: 24px;
        background: #f5f5f5;
        border-radius: 9999px;
        position: relative;

        .avatar-inner {
          width: 12.86px;
          height: 15.43px;
          background: #d2d5da;
          position: absolute;
          left: 2.57px;
          top: 1.29px;
        }
      }

      .user-email {
        width: 205px;
        color: black;
        font-size: 14px;
        font-family: "Manrope", sans-serif;
        font-weight: 400;
        word-wrap: break-word;
      }
    }

    .action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      overflow: hidden;

      .checkbox {
        width: 24px;
        height: 24px;
        appearance: none;
        background-color: #fff;
        border: 1px solid #8f97a9;
        border-radius: 3px;
        position: relative;
        cursor: pointer;

        &:checked {
          background-color: #ff1053;
          border: none;

          &::before {
            content: "✔";
            color: white;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}