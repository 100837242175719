@import "../../../assets/styles/mixins.scss";


.add-advetising-btn-create {
  width: 100%;
  max-width: 200px;
  height: 39px;
  background-color: #ff1053;
  color: white;
  border-radius: 8px;
  border: none;

  @media (min-width: 768px) {
    width: 226px;
  }

  @media (max-width: 768px) {
    max-width: 120px;
  }
}

.addDocumentsModal {
  position: absolute;
  background-color: white;
  max-width: 582px;
  min-height: 293px;
  border-radius: 20px;
  z-index: 5;
  padding: 40px 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    // position: unset;
  }
}

.documents-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    align-items: center;
    padding-right: 20px;
    width: 95vw;
  }
}

.documents-header-txt {
  @include text($size: 20px, $weight: 500, $line: 1rem);
  margin-bottom: 0;
}

.create-new-document {
  position: absolute;
  top: 40px;
  // height: 896px;
  max-width: 594px;
  width: 594px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 30px;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.add-documents-modal-btn {
  // width: 244px;
  max-width: 244px;
}

.add-documents-modal-save {
  background-color: #d2d5da;
  max-width: 244px;
}

.documents-modal-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  // align-items: center;
  backdrop-filter: blur(5px);
  z-index: 5;
}

.addDocumentsModal {
  width: 100%;
  position: absolute;
  background-color: white;
  max-width: 582px;
  min-height: 293px;
  border-radius: 20px;
  z-index: 5;
  padding: 40px 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 30px;
    margin: 0 10px;
  }
}

.create-new-office {
  // height: 896px;
  max-width: 594px;
  width: 594px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 30px;
  }
}

.attach-box {
  display: flex;
  gap: 5px;
  align-items: center;

  img {
    cursor: pointer;
  }
}

.attach-txt {
  @include text($size: 15px, $weight: 500, $line: 1rem);
  cursor: pointer;
}

.new-document-txt {
  @include text($size: 32px, $weight: 400, $line: 44px);
  margin-top: 0px;
  margin-bottom: 0px;
}

.user-selected {
  margin-top: 100px;
}


.doc-type {
  @include text($size: 14px, $weight: 400, $color: #8F97A9);
  margin-bottom: 0 !important;
}

.documents-select {
  height: 54px !important;
}

.document-select {
  width: 304px !important;
  height: 44px !important;
  margin-top: 7px;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.document-calendar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 8px;
  height: 44px;
  margin-top: 6px;
  background-color: #f0f0f0;
}

.mobile-document {
  display: none !important;
}

@media (max-width: 560px) {

  .documents-header-txt {
    @include text($size: 14px, $weight: 700, $color: #FF1053);
    margin-top: 0;
  }

  .document-calendar {
    // justify-content: start;
    width: 100%;

    // .calendar-icon {
    //   margin-left: 80px;
    // }

    .mobile-document {
      display: block !important;
      width: 115px;
    }

  }

  // .add-advetising-btn-create {
  //   display: none;
  // }
}

.document-cancel-btn {
  width: 100% !important;
  max-width: 230px !important;
}

.calendar-icon {
  cursor: pointer;
}

.document-type-label {
  margin-bottom: 0 !important;
}

// .documents-main-container {
//   min-width: 85vh;
// }

// .document-input-wrapper,
// .document-calendar,
// .documents-select-table {
//   @media (max-width: 768px) {
//     width: 95vw !important;
//   }
// }