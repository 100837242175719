@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.add-balance-main-box {
    width: 100%;
    @include flex($gap: 20px);
    align-items: flex-start;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
}

.rules-box {
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 304px;
    box-sizing: border-box;
}

.add-balance__rules-box {
    @include flex($gap: 20px, $direction: column);
    padding-bottom: 20px;
}

.blue-bg {
    background-color: #eaf8ff;
    color: #0077c3;
}

.rose-bg {
    background-color: #ffecf1;
    color: $defaultRedColor;
}

.pay-method-box {
    max-width: 205px;
    width: 100%;
    height: 116px;
    background-color: #f5f5f5;
    padding: 20px;
    @include flex($align: start, $direction: column);
    cursor: pointer;
    border-radius: 8px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        flex-direction: column;
    }
}

.pay-method__container {
    @include flex($gap: 20px);
    padding-bottom: 24px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.add-balance__main {
    background-color: $defaultWhiteColor;
    min-width: 736px;
    padding: 50px;
    border-radius: 20px;
    box-sizing: border-box;
    width: 800px;
    position: relative;

    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        padding: 20px;
    }
}


.add-balance__main-head {
    color: #000;
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 32px;

    @media (max-width: 768px) {
        font-size: 24px;
    }
}

.add-balance__main-body {
    width: 100%;
    height: 81px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    display: inline-flex;
    border: none;
    padding: 24px 0;
    position: relative;
}

.add-balance__buttons-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.pay-method-box__head {
    @include flex($justify: space-between, $align: center);
    width: 100%;
}

.active-balance-box {
    border: 3px solid #ff87a9;
    border-radius: 8px;
}

.copy-box {
    height: 32px;
    padding: 5px;
    background-color: $middleGray;
    border-radius: 6px;
    @include flex($justify: start, $gap: 5px, $align: center);
    width: fit-content;
    cursor: pointer;
    position: relative;


    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        display: inline-block;
        padding-right: 30px;

    }
}

.mobile-modal-box-balance {
    @media (max-width: 768px) {
        width: 300px !important;
    }
}

.mobile-modal-box-balance {
    @media (max-width: 390px) {
        width: 250px !important;
        height: 400px !important;
    }
}

.copy-btn {
    @media (max-width: 768px) {
        position: absolute;
        right: 5px;
    }
}

.balance-cancel-btn {
    width: 315px;
    height: 39px;
    background-color: transparent;
    border: 1px solid #8f97a9;
    color: $defaultRedColor;
    border-radius: 8px;
}

.balance-create-btn {
    width: 315px;
    height: 39px;
    background-color: $defaultRedColor;
    border: none;
    color: #FFFFFF;
    border-radius: 8px;
}

.add-balance-container {
    max-width: 195px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;

    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
    }
}

.current-balance {
    color: black;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
}

.balance-display {
    width: 100%;
    max-width: 195px;
    padding: 30px;
    background: #6c6ea0;
    border-radius: 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
    }
}


.copy-success {
    position: absolute;
    top: -30px;
    right: 260px !important;
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 2s;
}

.balance-amount {
    color: white;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 700;
    word-wrap: break-word;
}

.balance-currency {
    color: #999ac5;
    font-size: 20px;
    font-family: Manrope;
    font-weight: 400;
    word-wrap: break-word;
}

.balance-amount-input {
    width: 100%;
    align-self: stretch;
    height: 54px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    border: none;
    box-sizing: border-box;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.balance-amount-box {
    width: 100%;
    color: #8f97a9;
}

.add-balance__usd-label {
    position: absolute;
    top: 55px;
    right: 20px;
}

.wallet-num-box {
    @include flex($gap: 20px, $justify: flex-start);
    @include text($size: 14px, $weight: 400, );
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
    }
}

.capitalist-abb-balance-txt,
.wallet-num-box__head {
    @include text($size: 14px, $weight: 400, );
}



.USDT-balance__head-box {
    @include flex();
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
    }
}

.add-balance__x-partners {
    color: $levandaColor;
}

.close-finance-modal {
    cursor: pointer;
}

.add-balance-finance-error {
    margin-bottom: 20px;
}

.add-balance__modal-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 5;



    .purple-text {
        color: #6C6EA0;
    }

    &__main-box {
        // height: 339px;
        width: 100%;
        min-width: 610px;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 30px;
        background-color: #FFFFFF;
        border-radius: 20px;
        @include text($size: 32px, $weight: 400, $color: #000000);
        padding: 40px;
        // padding-bottom: 20px;
        position: relative;

        @media (max-width: 768px) {
            padding: 20px;
            min-width: unset;
        }

        .close-finance-modal {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .add-balance-modal-btn {
            @include button($width: 252px, $height: 39px, $bg: transparent, $border: 2px solid #FF1053, $hover-text-color: #FF1053, $radius: 8px, );
            color: #FF1053;
            font-weight: 600;
        }



        &__btns {
            @include flex($gap: 20px, $justify: center);
        }

        &__content {
            @include text($size: 14px, $weight: 400, $color: #000000);
            margin-bottom: 32px;
        }


        @media (max-width: 768px) {
            width: 100%;
            padding: 30px;
        }
    }
}

.add-balance__user-email {
    font-weight: 600;
}