@import "../../../assets/styles/mixins.scss";

.position-fixed {
    position: fixed;
    overflow: hidden;
    height: 100vh;
    z-index: 1002;
}

.add-advetising-btns {
    display: flex;
    justify-content: space-between;
    gap: 26px;
}

.add-advetising-btn-cancel {
    width: 100%;
    max-width: 138px;
    height: 39px;
    background-color: transparent;
    border: 1px solid #8f97a9;
    color: #ff1053;
    border-radius: 8px;

    @media (min-width: 768px) {
        width: 226px;
    }
}

.add-office-btn-create {
    width: 100%;
    max-width: 200px;
    height: 39px;
    background-color: #ff1053;
    color: white;
    border-radius: 8px;
    border: none;
    white-space: nowrap;

    @media (min-width: 768px) {
        width: 226px;
    }
}

.add-advetising-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    width: 100%;

    @media (max-width: 768px) {
        form {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        width: 100%;
    }
}

.advertising-systems-page-container {
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 20px;

    @media (min-width: 768px) {
        max-width: 520px;
    }

    &__absolute-layer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.5);
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
        /* Adds the blur effect */
    }
}

.add-advetising {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    padding: 20px 19px 0;

    @media (max-width: 768px) {
        padding: 20px 19px 0 0;
    }

    &__fb-ads {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 12px;

        @media (min-width: 768px) {
            align-items: center;
            flex-direction: column;
            align-items: start;
            justify-content: center;
        }
    }

    img {
        width: 64px;
    }

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.label-class {
    margin-bottom: 8px;
    color: #8f97a9;
}

.label-class-span {
    color: #ff1053;
}

.advetising-select-class>div {
    background-color: #f0f0f0;
    border: none;
    outline: none;

    input {
        color: black;
    }
}

.add-advetising-modal {
    position: absolute;
    background-color: white;
    max-width: 582px;
    min-height: 293px;
    border-radius: 20px;
    z-index: 5;
    padding: 40px 50px 40px 50px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 15px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.add-advetising-modal-btn {
    width: 100%;
    height: 39px;
    background-color: transparent;
    border: 2px solid #8f97a9;
    color: #ff1053;
    border-radius: 8px;
    font-weight: 700;
}

.add-advetising-modal-header {
    @include text($size: 32px, $weight: 500, $text-align: left);

    @media (max-width: 768px) {
        font-size: 24px;
        margin: 0;
    }
}

.error-input {
    border: 1px solid red;
}

.add-office-time-zone {
    border: none;
    height: 54px;
    border-radius: 8px;

    .time-zone-react-select__control {
        padding: inherit;
        border-radius: 8px;

    }

    .time-zone-react-select__menu-list {
        gap: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}


.add-office-time-zone div {
    background-color: #F0F0F0;
    color: #000000;
    grid-area: initial;
    display: flex;
    border: none;
    padding-left: 7px;
    height: 100%;
    padding-top: 7px;
    // @include text($size: 13px, $weight: 500, $text-align: center, );
    // padding: 5px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 8px;
}

.add-advetising-btn-cancel-ads {
    max-width: none;
}

.time-zone-react-select__menu {
    height: 160px !important;
    background-color: rgba(255, 255, 255, 1) !important;
}

.time-zone-react-select__menu-list,
.time-zone-react-select__option {
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(143, 151, 169, 1) !important;
    font-size: 14px;
    font-weight: 600 !important;
    cursor: pointer;
}

.time-zone-react-select__option--is-selected {
    color: rgba(0, 0, 0, 1) !important;
}

.time-zone-react-select__menu-list,
.time-zone-react-select__menu {
    width: 100% !important;
}

.time-zone-react-select__option:hover {
    background-color: #deebff !important;

}

.time-zone-react-select__option--is-selected:hover {
    background-color: #2684ff !important;
    color: white !important;
}