@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/mixins.scss";

.managers__head {
  @include flex($align: center, $justify: left, $gap: 30px);
}

.managers__table {
  width: 100%;
  max-width: 515px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.add-manager__btn {
  width: auto;
  padding: 0 15px;
}

.add-manager__box {
  @include flex($align: center, $justify: space-between);
}

.add-manager__managers {
  @include responsive-title-style(20px, 500, 14px, 700, #ff1053);
}

.add-manager__modal-container {
  @include flex($direction: column, $justify: center, $align: center);
  width: 100%;
  max-width: 594px;
  padding: 0 20px 30px;
  margin-top: 150px;

  @media (max-width: 768px) {
    max-width: 500px;
  }
}

.manager-modal__btn-box {
  @include flex($gap: 30px);
  margin-top: 30px;
}

.manager-modal__btn-cancel {
  width: 100%;
  max-width: 244px;
  font-family: Manrope;
}

.manager-modal-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 5;
}

.modal-container__add-manager {
  @include text($size: 32px, $weight: 500);
  margin: 20px auto 0;
}

.phone__addmanager {
  max-width: 100%;
}
