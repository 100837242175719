@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';


.finance-history-route {
    width: 100%;
    margin-top: 0;
}

.finance-history-row {
    width: 100%;
    align-self: start;
    // color: #000;
    // font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 18px;
}

.finance-input-icon {
    position: absolute;
    right: 10px;
    // top: 50%;
    // transform: translateY(-50%);
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.add-office-balance-modal {
    height: auto !important;
}

.add-personal-balance-box {
    gap: 0 !important;
}

.finance-modal-container {
    .ads-balance-currency {
        top: 55px;
    }
}

.finance-comment {
    @include text($size: 14px, $weight: 400, $color: #8F97A9);
    margin-bottom: 10px;
    margin-top: 20px;
}

.finance-user-search-icon {
    position: relative;
}

.finance-search-icon {
    position: absolute;
    top: 45px;
    // right: 40px;
}

.finance-table-container {
    overflow-y: auto;
    max-height: 204px;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    padding: 14px;
    box-sizing: border-box;
    width: 100%;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #cfd2da;
        border-radius: 3px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }


    .user-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;

        &:last-child {
            border-bottom: none;
        }

        .user-info {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            .avatar {
                width: 24px;
                height: 24px;
                background: #f5f5f5;
                border-radius: 9999px;
                position: relative;

                .avatar-inner {
                    width: 12.86px;
                    height: 15.43px;
                    background: #d2d5da;
                    position: absolute;
                    left: 2.57px;
                    top: 1.29px;
                }
            }

            .user-email {
                width: 205px;
                color: black;
                font-size: 14px;
                font-family: "Manrope", sans-serif;
                font-weight: 400;
                word-wrap: break-word;
            }
        }

        .action-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            overflow: hidden;

            .checkbox {
                width: 24px;
                height: 24px;
                appearance: none;
                background-color: #fff;
                border: 1px solid #8f97a9;
                border-radius: 3px;
                position: relative;
                cursor: pointer;

                &:checked {
                    background-color: #ff1053;
                    border: none;

                    &::before {
                        content: "✔";
                        color: white;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}


.finance-history {
    width: 100%;


    &__custom-select {
        &--margin-bottom {
            margin-bottom: 16px;
        }
    }

    .finance-history-calendar {
        display: flex;
        justify-content: space-between;
        // padding-left: 20px;
        border-radius: 8px;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .finance-history-calendar {
            justify-content: space-between;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .tabs {
        display: flex;
        border-bottom: 2px solid #ddd;
        margin-bottom: 20px;
        font-family: 'Manrope';

        .tab {
            padding: 10px 20px;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            transition: border-bottom 0.3s ease, color 0.3s ease;
            font-size: 20px;
            color: #8F97A9;
            font-weight: 500;
            border-left: none;
            border-right: none;
            background-color: transparent;
            border-top: none;
            font-family: 'Manrope';

            &.active {
                border-bottom: 2px solid $defaultRedColor;
                // font-weight: bold;
                color: $black;
                border-top: none;
                border-left: none;
                border-right: none;
                background-color: transparent;
            }

            &:hover {
                color: #000;
            }
        }
    }

    .content {
        border-top: none;
    }
}

.mobile-finance-txt {
    color: #FF1053;
    margin-top: -5px;
    font-weight: 700;
    font-size: 14px;
}

.finance-history-calendar {
    display: flex;
    justify-content: space-between;
    // padding-left: 20px;
    border-radius: 8px;
    cursor: pointer;
}

.bg-finance-history-calendar {
    background-color: #F0F0F0;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 768px) {
    .finance-history-calendar {
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.select-box {
    height: 44px;
    // background-color: $middleGray;
    gap: 1px;
}

.finance-short-select-service {
    width: 304px;
    border-radius: 8px;
    padding-left: 10px;
}

.ads-system-box {
    @include flex($gap: 28px, $align: center, $justify: flex-start);
}

.finance-history__short-selects-box {
    margin-top: 10px;
}

.finance-history__ads-select {
    .custom-react-select__control {
        width: 304px;
        border: none;
        height: 44px;
    }
}

.status-balance-office-box {
    justify-content: flex-start !important;
    gap: 20px !important;
}

.available-balance-foot-label {
    @include text($size: 14px, $weight: 400, $color: #D2D5DA);
    margin-top: 10px;
}

.balance-footer-txt {
    display: flex;
    justify-content: space-between;
}


.add-bonus-input {
    width: 110px !important;
    height: 54px;
}

.bonus-finance-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bonus-finance-txt {
    @include text($size: 14px, $weight: 400, $color: #000000);
    margin-top: 40px;
}

.add-bonus-to-finance-container {
    width: 290px;
    border: 1px solid #D9D9D9;
    padding: 20px;
    border-radius: 8px;
}

.bonus-modal {
    height: 560px;
    width: 1100px;
    padding-left: 60px;
}

.bonus-finance-title {
    @include text($size: 16px, $weight: 600, $color: #000000);
}

.bonuses-input-box {
    position: relative;
}

.percent-img-bonus {
    position: absolute;
    left: 80px;
    top: 50px;
}

.bonus-box-margin {
    margin-top: 10px;
    margin-bottom: 10px;
}

.bonus__add-new-bonus {
    justify-content: center;
    margin-top: 20px;
}

.bonus-modal-main-box-container {
    display: flex;
    gap: 20px;
}

.comment-box {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 20px auto;
}

label {
    margin: 0;
    font-size: 16px;
    color: #888;
}

.comment-input {
    height: 100px;
    padding: 12px;
    font-size: 14px;
    border-radius: 8px;
    border: none;
    resize: none;
    outline: none;
    background-color: #F0F0F0;
    margin-bottom: 20px;
}

.comment-input:focus {
    border-color: #888;
}

.submit-button {
    margin-top: 12px;
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
}

.submit-button:hover {
    background-color: #45a049;
}

.pay-method {
    margin: 0;
    color: #8F97A9;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.add-personal-balance-modal {
    margin-top: 10px;
}

.finance-mobile-header {
    display: none;
    @include text($size: 14px, $weight: 600, $color: #FF1053);
}

@media (max-width: 560px) {
    .react-datepicker__navigation--next {
        right: 60px;
    }

    .react-datepicker__month-container {
        max-width: 300;
    }

    .finance-mobile-header {
        margin-top: -20px;
        display: block;
    }

    .add-balance-bonus-box {
        display: none !important;
    }

    .react-datepicker__month,
    .date-picker-container {
        width: 320px !important;
    }

    .react-datepicker__month,
    .react-datepicker__header {
        margin-right: 30px;
    }

    .react-datepicker__navigation {
        right: 0;
    }
}

@media (max-width: 360px) {
    .react-datepicker__month-container {
        max-width: 280;
    }
}

.office-add-balance-status-txt {
    font-weight: 600;
}