@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.networks-item-elem {
    box-sizing: border-box;
    @include flex($gap: 20px, $direction: column, $justify: space-between);
    // width: 100%;
    max-width: 412px;
    height: 332px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.networks-item-elem-img {
    img {
        max-width: 100%;
    }
}

.networks-box-container {
    width: 100%;
    @include flex($gap: 20px, $wrap: wrap, $justify: center);
    margin: 20px 0;
}

.networks-item-elem-btn-connect {
    @include button($width: 100%, $height: 42px, $bg: $lightGray);
    @include text($size: 14px, $weight: 500, $text-align: center, $color: $black);
    max-width: 352px;
    border-radius: 8px;
}

.btn-connect-disabled {
    background-color: $middleGray;
}

.networks-box-home {
    min-height: 80vh;
    margin-top: 30px;
    display: block;
}

.networks-item-title {
    @include text($size: 16px, $weight: 600, $text-align: center, $color: $black);
}

.networks-item-status {
    @include text($size: 13px, $weight: 600, $text-align: center, $color: $defaultGray);
}

.networks-item-description {
    @include text($size: 13px, $weight: 400, $text-align: left, $color: $defaultGray);
    max-width: 352px;
}

.networks-box-sort {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    @include text($size: 13px, $weight: 400, $text-align: left, $color: $defaultGray);
}

.active-btn {
    background-color: $defaultRedColor;
    color: $defaultWhiteColor;
}

.active-btn:hover {
    background-color: $defaultRedColor;
    color: $defaultWhiteColor;
}

.ads-home-mbl-txt {
    @include text($size: 14px, $weight: 600, $text-align: left, $color: #FF1053);
    display: none;
    margin: 0;

    @media (max-width: 768px) {
        display: block;
    }
}

.coming-ads-soon {
    @include text($size: 13px, $weight: 700, $text-align: left, $color: #8F97A9);

}

.networks-box-sort div {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.networks-box-sort div.active-sort {
    // background-color: #f0f0f0; // Цвет фона для активного элемента
    font-weight: bold; // Сделать текст жирным
    color: #333; // Цвет текста
}

.networks-box-sort div:hover {
    background-color: #e0e0e0; // Фон при наведении
}