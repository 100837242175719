.user-agreement-link {
    text-decoration: none;
    color: #8F97A9;
}

.user-politics {
    cursor:pointer;
    text-decoration: none;
    color: #8F97A9;
}

.user-oferta {
    color: #8F97A9;

    @media (max-width: 768px) {
        padding-bottom: 10px;
    }
}