.user-select {
    position: relative;
    width: 100%;

    &__input-wrapper {
        width: 100%;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #ddd;
        background-color: #fff;
        z-index: 1000;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
    }

    &__item {
        padding: 8px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    &__email {
        padding: 8px;
        font-size: 14px;
    }

    // Стили для заблокированного инпута
    input:disabled {
        background-color: #f9f9f9; // Светлый серый фон
        color: #aaa; // Текст серого цвета
        // cursor: not-allowed; // Убираем pointer
    }

    // Стили для иконки при disabled
    .input-icon {
        // cursor: not-allowed; // Отключаем pointer для иконки
        opacity: 0.5; // Иконка станет полупрозрачной
    }

    // Отключаем hover эффект для заблокированного инпута
    input:disabled:hover {
        background-color: #f9f9f9;
    }
}