@import "../../../assets/styles/mixins.scss";

.layout-main {
    position: relative;
    background-color: #f9f9f9;
    padding: 0 20px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        padding: 0 16px;
    }

    > :last-child {
        margin-top: 20px; // auto;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    &--white-header {
        background-color: white;
    }

    &--min-height {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__column {
        width: 100%;
        display: flex;
        align-items: stretch;
    }

    &__main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: center;

            .registration__main {
                align-items: flex-start;
            }
        }
    }

    &__left-side {
        display: none;
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
        height: 100%;

        img {
            width: 100%;
        }

        @media (min-width: 768px) {
            display: flex;
            justify-content: stretch;
            align-items: stretch;
            background-color: white;
            align-items: center;
            width: 60%;
            height: 100%;
        }
    }

    &__header__left-text {
        @include text($size: 28px, $weight: 600, $line: 1rem);
        padding-top: 0;
    }

    &__footer {
        @include text($size: 13px, $weight: 400, $color: #8f97a9);
        padding-bottom: 15px;
    }

    &__header {
        margin-bottom: 25px;
    }

    &__footer,
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1300px;

        button {
            width: 100%;
            height: 40px;
            background-color: transparent;
            border: 2px solid #8f97a9;
            outline: none;
            border-radius: 8px;
            color: #ff1053;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        &__column {
            width: 100%;
            display: flex;
        }
    }

    .register-header-btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-weight: 600;
        padding: 0 16px;

        button {
            width: 114px;
            height: 40px;
            background-color: transparent;
            border: 2px solid #8f97a9;
            outline: none;
            border-radius: 8px;
            color: #ff1053;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        &__signin-section {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__signin-section {
        padding-bottom: 30px;
    }
}

.layout-main-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    z-index: 1001;
}

.header-right-side-container {
    display: flex;
    gap: 20px;
    align-items: center;
    position: relative;

    &--display-none {
        display: none !important;
    }

    @media (max-width: 990px) {
        &--column {
            flex-direction: column;
            align-items: start;
            gap: 8px;
        }
    }
}

.footer-right-side-container {
    @extend .header-right-side-container;
    height: 100%;
}

.content-wrapper {
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
    min-height: calc(100vh - 170px);
}

.content-wrapper--visible {
    opacity: 1;
}

.emojiFlag {
    width: 24px !important;
    height: 24px !important;
    border-radius: 100%;
    object-fit: cover;
}

.phone-dropdown-item {
    padding-left: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.layout-main__footer {
    margin-top: auto;
}