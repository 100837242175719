.relative {
  width: 100%;
  position: relative;
}

.label-container {
  display: flex;
  gap: 3px;
  align-items: center;
}

.label-required {
  color: #ff1053;
  margin: 0;
}

.empty {
  border: 1px solid red;
  box-shadow: 0 0 5px red;
  background-color: #ffcfd7;
}

.phone-dropdown-item {
  white-space: nowrap;
  cursor: pointer;
  padding: 8px 0;
}

.phone-dropdown {
  max-height: 269px; 
  overflow-y: auto; 
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
  z-index: 1000;
  min-width: 106px;
  padding: 0 0 0 16px;
  box-sizing: border-box;
  position: absolute; 
  top: 54px;
  left: 0; 
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 90px;
  }
}

.phone-dropdown::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.phone-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.phone-dropdown::-webkit-slider-thumb {
  background-color: #888;
  border-radius: 5px;
}

.dropdown-style {
  color: #6c6ea0;
  fill: #6c6ea0;
  cursor: pointer;

  &:hover {
    color: hsl(0, 0%, 60%);
    fill: hsl(0, 0%, 60%);
  }
}

.eye-class {
  cursor: pointer;
  position: absolute;
  top: 45px;
  right: 24px;
}

.right-text {
  @extend .eye-class;
  cursor: none;
  color: #8f97a9;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.copy-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.copy-success {
  position: absolute;
  top: -30px;
  right: 0;
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 2s;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
