@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.user-settings {
    &__title {
        width: 100%;
        font-family: Manrope;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
    }
}

.settings-input {
    width: 100%;

    height: 54px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: none;
    font-size: 16px;

    &::placeholder {
        color: #8f97a9;
        opacity: 1;
    }
}

.networks-box-form {
    form {
        width: 100%;
    }
}

.two-fatctor-auth__box {
    @include flex();
    @include text($size: 14px, $weight: 400);
}

.connect-two-auth {
    color: $defaultRedColor;
    cursor: pointer;
    font-weight: 600;
}

.networks-box-page * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.networks-box-page {
    display: flex;
    flex-direction: column;
}

.user-setting-manage-phone {
    width: 110px !important;
}

.networks-box__menu {
    width: 100%;
    margin-bottom: 42px;

    nav {
        ul {
            display: flex;
            list-style-type: none;
            gap: 24px;
            padding: 0;
            color: #00100b;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            li a {
                color: #00100b;
            }
        }
    }
    @include media-smartphone {
        display: none;
    }
}

.networks-box__section {
    width: 100%;
    display: flex;
    justify-content: center;
}

.networks-box__balance {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 196px;

    .networks-balance__title {
        h3 {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .networks-balance__indicator {
        border-radius: 12px;
        background: #6c6ea0;

        display: flex;
        min-width: 195px;
        max-width: 100%;
        padding: 20px;
        align-items: center;
        gap: 10px;
        font-style: normal;
        line-height: normal;
        color: #999ac5;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
            color: #fff;
            font-weight: 700;
        }
    }

    .networks-balance__fillBTN {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        text-align: center;

        button {
            border-radius: 8px;
            border: 1px solid #8f97a9;
            display: flex;
            width: 196px;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #ff1053;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &--hidden-question {
            display: none;
            color: #8f97a9;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
                color: #ff1053;
            }

            @include media-smartphone {
                display: block;
            }
        }
    }
}

.networks-box-form {
    border-radius: 20px;
    background: #fff;
    padding: 40px;
    max-width: 628px;

    form {
        display: flex;
        flex-direction: column;
        gap: 26px;

        input {
            padding: 16px 24px;
            margin: 0;
        }

        p {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .names-input {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 16px 24px;

            &::placeholder {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .emails-input {
            padding: 16px 24px;
            background: #fbfbfb;
            color: #000;

            &::placeholder {
                color: #8f97a9;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .phone-input {
            max-width: none;

            &::placeholder {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .inputs-labels {
            color: #8f97a9;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.networks-box-form .label-container {
    margin-bottom: 8px;
}

.phone-box-label .label-container {
    margin-bottom: 0;
}

.phone-box-label-container .label-container {
    gap: 4px;

    p {
        color: #ff1053;
        font-size: 14px;
    }
}

.phone-box-label-container .register-label {
    color: #8f97a9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.phone-box-label .phone-select {
    width: auto;
    padding: 16px 24px;
}

.soc-media {
    .label-container {
        gap: 4px;

        p {
            color: #ff1053;
            font-size: 14px;
        }
    }

    .select-box {
        height: auto;
        background: none;

        .relative {
            .label-container {
                display: none;
            }
        }
    }
}

.networks-box-form form p {
    color: #8f97a9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.US_save_BTN {
    button {
        padding: 10px 24px;
    }
}

@include media-tablet {
    .networks-box__section {
        flex-direction: column;
    }

    .networks-box__balance {
        max-width: 100%;
        margin-right: 0;
        align-items: center;
        margin-bottom: 24px;
        gap: 8px;

        & > div {
            width: 100%;
        }

        .networks-balance__title {
            h3 {
                font-size: 16px;
            }
        }

        .networks-balance__indicator {
            margin-bottom: 4px;
        }
    }

    .networks-box-form {
        max-width: 100%;
    }

    .networks-box-form {
        form {
            .emails-input {
                &::placeholder {
                    color: #000000;
                }
            }
        }
    }
}

@include media-smartphone {
    .networks-balance__fillBTN {
        button {
            width: 100% !important;
        }
    }

    .networks-box-form {
        padding: 25px;
    }
}

@include media-mobile {
    .networks-box-form {
        padding: 15px !important;
    }
}

.copy-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
}

.copy-success {
    position: absolute;
    top: -30px;
    right: 0;
    background-color: #4caf50;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 2s;
}

@keyframes fadeInOut {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.user-settings-save {
    width: 184px;
}
