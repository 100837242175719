@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.two-auth-txt {
  @include text($size: 32px,);
  margin-bottom: 0;
  margin-top: 10px;

}

.two-auth-modal {
  width: 521px;
  height: 359px;
}

.two-auth-code-txt {
  margin-top: 30px;
  @include text($size: 14px,);
}

.two-auth-code-label {
  margin-top: 20px;
}

.two-auth-code-btn {
  margin-top: 25px;
}

.two-auth-code-cancel {
  width: 207px;
  max-width: 207px;
}

.two-auth-code-input {
  margin-top: 10px;
}