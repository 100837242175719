.status-section {
  &__title {
    font-weight: 600;
  }
  &__status-toggler {
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
    padding: 10px 0;
  }
  &__status-toggler-text {
    font-size: 14px;
  }

  .manager-user__page-status {
    margin-top: 0px;
    display: flex;
    align-items: center;
  }

  .status-info-manager {
    margin-left: 12px;
  }
}

.status-tooltip {
  position: absolute;
  background-color: #FFFFFF;
  width: 160px;
  padding: 10px; /* Adds some padding around the text */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15); /* Softer shadow */
  margin-bottom: 30px;
  z-index: 100; /* Ensure the tooltip is on top of other elements */
}

.status-new-tooltip {
  font-size: 14px; /* Adjust font size as needed */
  color: #000000; /* Text color */
}

/* Optional if you need to adjust positioning more dynamically */
.status-tooltip::before {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust based on your needs */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  z-index: -1;
}

