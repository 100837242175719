@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.landing-page-head {
    display: flex;
    gap: 28px;
    justify-content: space-between;

    @media (max-width: 768px) {
        gap: 0;
    }

    &--white-background {
        width: 100%;
        background-color: white;
        border-bottom-left-radius: 50px 50px;
        border-bottom-right-radius: 50px 50px;

        @media (max-width: 768px) {
            border-bottom-left-radius: 30px 30px;
            border-bottom-right-radius: 30px 30px;
        }
    }

    &--container {
        width: 100%;
        box-sizing: border-box;
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 17px;
    }

    &__left-section {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
    }

    &__title__bottom {
        @include text($size: 20px, $weight: 400, $color: $defaultGray);
        margin-top: 1rem;
        font-size: 1.2rem;

        @media (max-width: 768px) {
            align-self: start;
            padding-top: 16px;
            font-size: 18px !important;
        }

        &--mobile-hidden {
            @media (max-width: 768px) {
                display: none !important;
            }
        }

        &--desktop-hidden {
            @media (min-width: 768px) {
                display: none !important;
                align-self: start;
            }
        }
    }

    &__btn-box {
        @include flex($gap: 15px, $justify: flex-start);
        margin-top: 2rem;
        display: flex;
        gap: 1rem;

        @media (max-width: 768px) {
            gap: 0.5rem;
            width: 100%;
            padding-bottom: 40px;
            justify-content: space-around;
        }

        &--mobile-hidden {
            @media (max-width: 768px) {
                display: none !important;
            }
        }

        &--desktop-hidden {
            @media (min-width: 768px) {
                display: none !important;
            }
        }
    }

    &__title {
        font-size: 56px;
        font-weight: 700;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 30px;
            text-align: start;
            z-index: 1;
        }
    }

    img {
        transform: translateX(-20px);
    }

    &__title__bottom {
        margin: 0;
        font-size: 32px;
    }

    &__btn-box {
        margin-top: 2rem;
        display: flex;
        gap: 1rem;
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 30px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;

        &__title__bottom {
            font-size: 1rem;
        }

        .landing-page__register-btn {
            width: 215px;
        }

        .landing-page__auth-btn {
            width: 96px;
        }

        .landing-page-head__img {
            width: 75%;
            align-self: start;
            padding-left: 17px;
        }
    }
}

.landing-page__register-btn {
    @include button($width: 287px, $height: 47px, $bg: $defaultRedColor, $radius: 8px);
}

.landing-page__auth-btn {
    @include button($width: 139px,
        $height: 47px,
        $bg: #fff5f8,
        $btn-text-color: $defaultRedColor,
        $border: 2px solid $defaultRedColor,
        $radius: 8px);
}

.blocks-container {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
    }
}

.block-component {
    width: 100%;
    max-width: 500px;
    height: 100%;
    min-height: 300px;
    max-height: 300px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 1124px) {
        min-height: unset;
        max-height: unset;
        padding: 20px;
    }

    @media (max-width: 768px) {
        padding: 20px 2rem;
        min-height: unset;
        max-height: unset;
    }

    h2 {
        font-size: 32px;
        margin-bottom: 32px;
        margin-top: 0;

        @media (max-width: 768px) {
            margin-top: 0;
            margin-bottom: 24px;
            font-size: 24px;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            padding-left: 20px;
            margin-top: 10px;
            font-size: 20px;

            @media (max-width: 768px) {
                margin-bottom: 0;
                font-size: 16px;
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                transform: translate(0, 70%);
                width: 10px;
                height: 10px;
                border: 2px solid #ff0055;
                border-radius: 50%;
                background-color: white;

                @media (max-width: 768px) {
                    transform: translate(0, 40%);
                }
            }
        }
    }
}

.landing-page__start-work {
    background-color: $lightWeightGray;
    @include flex($gap: 28px, $direction: column, $align: center);
    margin-top: 70px;
    border-radius: 10px;
    margin-bottom: 70px;
    padding: 50px 20px;
}

.landing-page__start-btn {
    @include button($width: 194px, $height: 47px, $bg: $defaultRedColor, $radius: 8px);
}

.step-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #c3c7ce;
    text-align: center;
    width: 100%;
    max-width: 300px;
    min-height: 212px;
    position: relative;

    @media (max-width: 768px) {
        max-width: unset;
    }

    &__image {
        width: 100%;
        max-width: 150px;
        margin-bottom: 1rem;
    }

    &__description {
        font-size: 20px;
        color: #333;

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
}

.steps-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-items: start;
    gap: 20px;
    background-color: #eaeaea;
}

.step-card__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.registration-form__landing {
    max-width: 135px;
    height: 125px;
    padding-left: 5px;
    background-color: #ffffff;
    border-radius: 8px;
    padding-right: 5px;
}

.register-input__landing {
    width: 115px;
    height: 20px;
}

.register-select__landing {
    width: 38px;
    height: 20px;
}

.registration-input__landing {
    width: 75px;
    height: 20px;
}

.select-box__landing {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: auto;
    }
}

.landing-card__btn {
    @include button($width: 110px, $height: 20px, $bg: $defaultRedColor, $radius: 8px);
    @include text($size: 7px, $weight: 300, $color: $defaultWhiteColor);
    margin-top: 15px;
}

.register__landing-text {
    @include text($size: 12px, $weight: 500);
    margin-bottom: 10px;
}

.landing-manager {
    width: 70px;
    height: 25px;
    border-radius: 15px;
    @include text($size: 12px, $weight: 400, $color: $defaultWhiteColor);
    background-color: $levandaColor;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 40px;
    right: 70px;
}

.office-landing__arrow {
    position: absolute;
    bottom: 50px;
    right: 110px;
}

.finance-box {
    width: 100%;
    max-width: 400px;
    height: 254px;
    box-sizing: border-box;
    background-color: $defaultWhiteColor;
    border-radius: 8px;
    @include text($size: 20px, $weight: 400);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0 10px;
    }
}

.finance-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.landing-page__finance-btn {
    @include button($width: 167px, $height: 62px, $bg: #d2d5da, $radius: 8px);
    @include text($size: 16px, $weight: 600, $text-align: center);
}

.finance-title__text {
    @include text($size: 40px, $weight: 700, $text-align: center);
    margin: 20px 0;

    @media (max-width: 768px) {
        @include text($size: 32px, $weight: 700, $text-align: center);
        margin: 0;
    }

    &--faq {
        padding-top: 40px;
        margin-bottom: 30px;
    }

    &--add-money {
        margin-bottom: 30px;
    }
}

.faq__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.faq__box {
    width: 100%;
    height: 100%;
    background-color: $defaultWhiteColor;
    border-radius: 20px;
    padding: 20px;
}

.faq__head {
    display: flex;
    justify-content: center;
    margin-bottom: 14px;

    img {
        cursor: pointer;
    }
}

.faq__title {
    @include text($size: 32px, $weight: 500);

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.faq__minus-box {
    @media (min-width: 768px) {
        height: 100% !important;
    }
}

.faq__answers {
    max-width: 845px;

    p {
        margin: 25px 0;
    }
}

.landing__buttom {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.landing__buttom-title {
    @include text($size: 28px, $weight: 600);
    margin-bottom: 0;
}

.landing__main-title {
    @include text($size: 16px, $weight: 600);
}

.landing__main-title__span {
    color: $defaultRedColor;
}

.landing__buttom__right {
    @include text($size: 13px, $weight: 500, $color: $defaultGray);
    text-align: end;

    @media (max-width: 768px) {
        text-align: unset;
    }
}

.email-link {
    color: #ff1053;
    font-weight: 600;
}