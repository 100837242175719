@import "../../../assets/styles/mixins.scss";

.left-side-header {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;

    &--small-gap {
        gap: 12px;
    }

    button {
        width: 108px !important;
        color: #ff1053 !important;
        border: 1px solid #8f97a9 !important;
    }

    &__balance {
        font-size: 20px;

        span {
            font-weight: 700;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }

    &__role,
    &__role * {
        box-sizing: border-box;
        font-family: "Manrope-Bold", sans-serif;
        font-size: 15px;
        font-weight: 700;
    }
    &__role {
        border-radius: 16px;
        padding: 8px 0;
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        position: relative;

        &--manager {
            color: #0077c3;
            background-color: #eaf8ff;
            padding: 8px 16px 8px 16px;
            border-radius: 16px;
        }

        &--admin {
            color: #37a72e;
            background-color: #ebffea;
            padding: 8px 16px 8px 16px;
            border-radius: 16px;
        }
    }
}

.header-right-side-user {
    display: flex;
    align-items: center;
    gap: 10px;

    &--show-on-mobile {
        display: none;
        cursor: pointer;
        @media (max-width: 768px) {
            display: flex;
        }
    }
    &--hide-on-mobile {
        display: flex;
        @media (max-width: 768px) {
            display: none !important;
        }
    }
}

.header-right-side-img {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__arrow {
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.header-right-side-email {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
    .header-right-side-email p {
        display: none;
    }

    .header-right-side-img {
        display: none;
    }

    .header-right-side {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.header-right-side-modal {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 8px;
    width: 295px;
    background-color: white;
    position: absolute;
    top: 50px;
    z-index: 10;
    right: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 30px 20px;
    font-weight: 600;
    box-sizing: border-box;

    @media (max-width: 768px) {
        top: 0;
    }

    &__manager-tab-menu {
        display: none;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    &__email-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-bottom: 45px;

        img {
            cursor: pointer;
        }
    }

    &__icon {
        cursor: pointer;
    }
}

.header-right-side-modal-box {
    @include flex($gap: 5px, $justify: start);
    cursor: pointer;
    padding: 8px 0;

    // &:hover {
    //     background-color: darken(#3498db, 20%);
    // }
}

hr {
    background-color: #f0f0f0;
}

.header-signin-section {
    display: flex;
    align-items: center;
    gap: 32px;

    p {
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .header-signin-section {
        display: none;
    }
}

.header__burger-menu {
    cursor: pointer;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
}

.header-right-side-modal-box .active {
    color: #ff1053;
}
.header-mbl-active-route {
    color: #FF1053;
}

.header-line {
    background-color: #f0f0f0;
    height: 1px;
    width: 205px;
    border: none;
    display: flex;
    justify-content: start;
    text-align: start;
    margin: 20px 0 0 0;
}

.header-line-bottom {
    margin-top: 0;
}

.open-notify-tkt {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 5px;

}

.notification-count {
    color: red;
}