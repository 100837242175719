.custom-select {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.custom-select__selected {
  background: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;

  @media (max-width: 768px) {
    padding: 10px 24px;
  }

  &.unselected {
    color: #8f97a9;
  }
}

.custom-select__selected:hover {
  background: #e0e0e0;
}

.custom-select__icon {
  margin-left: auto;
}

.custom-select__options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  z-index: 10;
}

.custom-select__option {
  padding: 10px;
  cursor: pointer;
}

.custom-select__option:hover {
  background: #f0f0f0;
}

.custom-select__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-select__check-icon {
  margin-left: 8px;
}

.custom-select {
  &::placeholder {
    color: #8f97a9;
  }

  option[value=""] {
    color: #8f97a9;
  }
}

// .status-active {
//   color: #B9F6B5;
// }

// .status-suspended {
//   color: #D2D5DA;
// }

// .status-rejected {
//   color: #FFCFD7;
// }

// .status-completed {
//   color: #B9F6B5;
// }