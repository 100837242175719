@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.header-absolute-background-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: white;
    z-index: 0;
}

.user-agreement-box * {
    padding: 0;
    box-sizing: border-box;
}

.user-agreement-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 30px;
    margin-top: 24px;
    margin-bottom: 30px;

    &__section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    &__title {
        @include text($size: 24px, $weight: 600);
        width: 100%;
        margin-bottom: 12px;
    }

    &__content {
        width: 100%;
        max-width: 67.5%;
    }

    &__intro {
        margin-bottom: 12px;

        p {
            font-size: 14px;
            font-weight: normal;

            & > span {
                color: $linkColor;
            }
        }
    }

    &__rules {
        font-size: 14px;

        ol {
            list-style-position: inside;
        }
    }

    &__rule {
        ul {
            list-style-position: inside;
            list-style-type: disc;
            padding-left: 25px;

            li span {
                color: $linkColor;
            }

            li::marker {
                font-size: 10px;
            }
        }
    }

    &__rule--1 {
        ul {
            padding-left: 15px;
        }
    }
}

@include media-laptop {
    .user-agreement-box__content {
        max-width: none;
    }
}

@include media-tablet {
    .user-agreement-box__title {
        font-size: 20px;
    }
}

@include media-mobile {
    .user-agreement-box__title {
        font-size: responsive-font-size(0.1, 0.7);
    }

    .user-agreement-box {
        padding: 20px;
    }

    .user-agreement-box__rule ul {
        padding-left: 10px;
    }
}
