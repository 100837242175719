@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

html,
body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
}

input {
  font-size: 16px;
}

input[type='password'] {
  font-size: 22px;
}

::-webkit-scrollbar {
  width: 5px;
  color: #d2d5da;
  scrollbar-color: #d2d5da;
}