.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.modal-content {
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 8px;
  max-width: 500px;
  z-index: 1001;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content-2fa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;

  h2 {
    color: #000;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    color: #666;
  }

  .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    label {
      display: block;
      margin-bottom: 5px;
    }
    .input-field {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      font-weight: bold;
      cursor: pointer;
      &.cancel-button {
        background-color: #ccc;
        color: #333;
      }
      &.confirm-button {
        background-color: #e53e3e;
        color: #fff;
      }
    }
  }
}

.two-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cancel-btn {
  width: 100%;
  height: 39px;
  background-color: transparent;
  border: 1px solid #8f97a9;
  color: #ff1053;
  border-radius: 8px;
  font-weight: 700;
}

.confirm-btn {
  width: 100%;
  height: 39px;
  background-color: #ff1053;
  color: #fff;
  border-radius: 8px;
  font-weight: 700;
}
