.manager-contact-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 412px;
  gap: 10px;

  @media (max-width: 768px) {
    max-width: unset;
    width: 100%;
    box-sizing: border-box;
  }

  &__title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  &__status-title {
    margin: 32px 0 0 0;
    font-size: 16px;
    font-weight: 600;
  }
}

.manager-page__status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    color: black;
    font-size: 14px;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
  }

  img {
    cursor: pointer;
  }
}

.email-copy__box {
  position: relative;
  width: 100%;
}

.email-copy__img {
  position: absolute;
  top: 45px;
  right: 40px;
  z-index: 2;
  cursor: pointer;
}

.messanger-copy__img {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
}

.contact-info__modal-btns {
  display: flex;
  gap: 20px;
}

.contact-info__cancel-btn {
  background-color: transparent;
  border: 2px solid #8f97a9;
  color: #ff1053;
  width: 100%;
  max-width: 244px;
  height: 39px;
  border-radius: 8px;
}

.contact-info__add-btn {
  background-color: #d2d5da;
  border: none;
  color: white;
  width: 100%;
  max-width: 244px;
  height: 39px;
  border-radius: 8px;
}

.default-btn-color {
  background-color: #ff1053;
}

.input-box-container {
  width: 100%;
}

.status-box {
  display: flex;
  flex-direction: column;
  gap: 13px;

  @media (max-width: 768px) {
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
  }
}

.manager-contact-info-phone {
  margin-bottom: 8px;
}

.contact-info__select {
  width: 90px;

  .custom-react-select__control {
    // width: 106px;
    height: 52px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 90px;
    }
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }
}