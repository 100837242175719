@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";


.faq {

  &__add-qusetion-box {
    @include flex($justify: space-between);
    width: 100%;
  }

  &__head-title {
    @include text($size: 20px, $weight: 500, );
    margin: 0;

    @media (max-width: 990px) {
      display: none;
    }
  }

  .edit-box-images {
    display: flex;
    gap: 10px;
  }

  &__mobile-head-title {
    display: none;

    @media (max-width: 990px) {
      display: block;
      color: #FF1053;
      font-weight: 600;
      font-size: 14px;
      margin: 0;
    }
  }

  .question-box {
    width: 100%;
    background-color: #FFFFFF;
    height: 171px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin-top: 20px;
    box-sizing: border-box;


    @media (max-width: 990px) {
      padding: 20px;
      margin: 10px;
      height: 250px;
    }

    &__content {
      display: flex;
      flex-direction: column;

      @media (max-width: 990px) {
        margin-top: 40px;
      }
    }

    .faq-arrow {
      // margin-top: 20px;
      cursor: pointer;
    }

    .faq-question-title {
      @include text($size: 24px, $weight: 600, );
      margin-bottom: 0;
    }

    .faq-question-edit-box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: end;
    }

    .faq-question-edit-user-box {
      justify-content: start;
    }
  }

  .add-question-btn {
    @media (max-width: 900px) {
      max-width: fit-content;
      padding: 0 20px;
    }
  }

  .faq-question-edit-box.faq-manager-edit-box {
    @media (max-width: 900px) {
      justify-content: space-between;
      padding-bottom: 10px;
    }
  }
}

.modal-delete-confirmation {
  justify-content: center;

  .modal-text {
    font-size: 32px;
  }
}