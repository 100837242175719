@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.administrator-home {
    &__flex-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    &__networks-box-sort {
        display: flex;
        gap: 10px;

        @include text($size: 13px, $weight: 400, $text-align: left, $color: $defaultGray);
    }

    &__button {
        width: 100%;
        max-width: 195px;
        height: 40px;
        background-color: transparent;
        border: 2px solid #d2d5da;
        outline: none;
        border-radius: 8px;
        color: #6c6ea0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
    }
}

.addOfficeModal {
    position: absolute;
    background-color: white;
    max-width: 582px;
    min-height: 293px;
    border-radius: 20px;
    z-index: 5;
    padding: 40px 50px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 15px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        position: unset;
    }
}

.ads-network-name {
    width: 514px;
}

.networks-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}
