.time-zone-react-select__input {
  display: none;
}

.time-zone-react-select__indicator {
  cursor: pointer;
}

.time-select-wrapper {
  width: 100%;
}

.time-zone-react-select__control {
  padding-left: 24px; // Отступ слева
  padding-right: 24px; // Отступ справа
}

.time-zone-react-select__value-container.custom-react-select__value-container--has-value {
  padding: 2px 0;
}