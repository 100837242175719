

.contacts-container {
  max-width: 1276px;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 20px;

  .contacts-soc-item {
    width: 295px;
    background-color: #F9FAFB;
    border-radius: 50px;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-between;
    padding-right: 10px;
  }

  .contacts-elements-main-box {
    display: flex;
    gap: 20px;

    @media (max-width: 1000px) {
      flex-direction: column;
  }
  }

  .contacts-main-title {
    color: #000000;
    font-size: 24px;
    font-weight: 600;
  }

  .contact-company-name {
    color: #6C6EA0;
    font-size: 16px;
    font-weight: 700;
  }

  .contact-company-adress {
    margin: 0;
  }

  .contact-company-adress-second {
    margin: 0;
  }
  .contact-item-title {
    color: #6C6EA0;
    font-size: 15px;
    font-weight: 700;
  }

  .contact-sopy-btn {
    margin-top: 23px;
    margin-right: 10px;
    cursor: pointer;
  }

  .contaci-text {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      margin: 0;
    }
  }

  .contac-text-left {
    display: flex;
    gap: 10px;
  }
}
