.manager-user {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    // min-height: 70vh;
  }

  &__user-info {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;

    flex-wrap: wrap;
    padding: 20px 20px 20px 20px;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: unset;
      background-color: white;
      // margin-bottom: 20px;

      img {
        display: none;
      }
    }
  }

  &__user-row {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 20px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      img {
        display: none;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }

  &__back-btn,
  &__save-btn {
    background-color: #ff4b4b;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background-color: #ebedec;
    }
  }

  &__ticket-btn {
    background-color: #ff4b4b;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background-color: #ebedec;
    }
  }

  &__back-btn {
    background-color: #cccccc;
  }

  &__copy-btn {
    cursor: pointer;
    position: absolute;
    top: 45px;
    right: 10px;
  }

  &__input-phone {
    width: calc(100% - 40px) !important;
  }

  &__status {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__close-page-icon {
    display: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: block;
      transform: translate(6px, -6px) scale(1.1);
    }
  }

  &__close-icon {
    display: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: block;
      // transform: translate(6px, -6px) scale(1.1);
    }
  }
}

.manager-user__buttons {
  display: flex;
  justify-content: flex-start;
}

.manager-user__user-info {
  flex-wrap: nowrap;
}

.user-info__email.managers-user-info {
  width: unset;
}

.manager-user__back-btn {
  background-color: transparent;
  border: 2px solid #8f97a9;
  color: #ff1053;
  border-radius: 8px;
  width: 244px;
  height: 39px;
  font-weight: 600;
  font-size: 14px;
  font-family: Manrope;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.manager-user__ticket-btn {
  background-color: transparent;
  border: 2px solid #8f97a9;
  color: #ff1053;
  border-radius: 8px;
  width: 132px;
  height: 39px;
  font-weight: 600;
  font-size: 14px;
  font-family: Manrope;
  text-align: center;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    // display: none;
    width: 100%;
  }
}

.user-info {
  &__avatar {
    width: 48px;
  }

  &__email {
    color: black;
    font-size: 24px;
    font-family: "Manrope";
    font-weight: 600;
    word-wrap: break-word;
  }

  &__status {
    width: 110px;
    height: 20px;
    padding: 2px 16px;
    background: #b9f6b5;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &--inactive {
      background: #d2d5da;
    }
  }

  &__section {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .status__text {
    color: #176c10;
    font-size: 12px;
    font-family: "Manrope";
    font-weight: 600;
    word-wrap: break-word;
  }
}

.contact-info {
  flex: 1;
  min-width: 300px;
}

.phone-box {
  position: relative;
}

.user-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.add-user-btn {
  margin-top: 10px;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
}

@media (max-width: 768px) {
  .manager-page__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .user-list {
    margin-right: 0;
  }
}

.user-info__status.status-hot {
  background-color: #FFC98B; 
  color: #856137; 
  min-width: fit-content;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
}

.user-info__status.status-spam  {
  background-color: #FFCFD7; 
  color: #D80027; 
  min-width: fit-content;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
}

.user-info__status.status-new {
  background-color: #B9F6B5; // оранжевый фон
  color: #176C10; // коричневый текст
  min-width: fit-content;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
}

.user-info__status.status-inactive {
  margin-top: 13px;
  background-color: #D2D5DA; // оранжевый фон
  color: #4D576F; // коричневый текст
  min-width: fit-content;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
}

.user-info__status.status-client {
  background-color: #E5F1FF; // оранжевый фон
  color: #778AA1; // коричневый текст
  min-width: fit-content;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
}