$defaultRedColor: #ff1053;
$black: #000000;
$defaultGray: #8f97a9;
$lightGray: #d2d5da;
$middleGray: #f0f0f0;
$bg-color: #f0f0f0;
$lightWeightGray: #eaebee;
$maxWidth: 1300px;
$defaultWhiteColor: #ffffff;
$levandaColor: #6C6EA0;
$datePickerColor: #0A1811;
$MoonColorGray: #6C7470;
$linkColor: #0052b4;
$breakpoint-mobile: 320px;
$breakpoint-smartphone: 576px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-large-laptop: 1440px;
$breakpoint-desktop: 1920px;
$breakpoint-large-desktop: 1920px;
