.commission-section {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.commission-section__select {
  width: 239px !important;

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.commission-box {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: unset;
  }

  .advertising-system {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }

    .select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      background: #f0f0f0;
      border: 1px solid;
      border-radius: 8px;
    }
  }

  .select-box.user-contact__select-box {
    border: none;
  }

  .percentage-box-img {
    position: absolute;
    z-index: 5;
    top: 40px;
    right: 20px;
  }

  .percent-input {
    margin-top: 7px;
    height: 45px;
    width: 101px !important;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  .percent-done-box {
    width: 48px;
    height: 48px;
    background-color: #F0F0F0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .percent-add {
    background-color: #FF1053;
  }

  .comission-percent-box {
    position: relative;
  }

  .percentage {
    display: flex;
    flex-direction: column;

    .percentage-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 101px;
      height: 44px;
      padding-right: 14px;
      background: #f0f0f0;
      border: none;
      border-radius: 8px;

      @media screen and (max-width: 768px) {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

.network-table-elem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.network-table-elem-value {
  display: flex;
  align-items: center;
}

.network-table-elem-key {
  width: 120px;
}

.network-table-elem-change {
  color: #6C6EA0;
  display: flex;
  gap: 10px;

  p {
    cursor: pointer;
  }
}

.network-comission-error {
  width: 250px;
}

.percent-input-change-value {
  background-color: #F0F0F0;
  width: 101px;
  height: 44px;
  border: none;
  border-radius: 8px;
  margin-right: 5px;
  padding-left: 4px;
}

.save-percent-btn {
  margin-right: 80px;
  border-radius: 8px;
  border: none;
  background-color: #F0F0F0;
  height: 44px;
  margin-top: 4px;
}

.add-adv-system {
  color: #6C6EA0;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 22px;
  cursor: pointer;
}