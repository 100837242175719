@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/variables.scss";

.ticket-menu {
  width: 100%;

  &__title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  &__title-text {
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 14px;
      color: #ff1053;
      font-weight: 600;
    }
  }

  &__select {
    div {
      color: #8f97a9;
      font-family: Manrope;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  // div {
  //   color: #000;
  //   font-family: Manrope;
  //   font-size: 20px;
  //   font-style: normal;
  //   font-weight: 500;
  //   line-height: normal;
  // }
}

.ticket-tickets-theme {
  margin-bottom: -10px;
  color: #8f97a9;
  font-size: 14px;
  font-weight: 400;

  span {
    color: #ff1053;
  }
}

.notify-ticket-date-box {
  display: flex;
  flex-direction: column;
}

.tickets-btn {
  @include button($width: 155px, $height: 38px, $bg: #ff1053);
  @include text($size: 14px, $weight: 500, $text-align: center, $color: white);
  // margin-top: 40px;

  // @media (max-width: 990px) {
  //   &--mobile-hidden {
  //     display: none;
  //   }
  // }

  @media (min-width: 990px) {
    &--desktop-hidden {
      display: none;
    }
  }
}

.tickets-container-notify {
  overflow: scroll;
  max-height: 570px;
  background-color: #FAFAFA;
  width: 450px;
  padding: 10px;
}



.notify-ticket-box {
  background-color: #FFFFFF;
  border-radius: 8px;
  height: 72px;
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  justify-content: space-between;
  cursor: pointer;

  &__left {
    display: flex;
    gap: 5px;
  }
}

.notify-user-email-ticket {
  color: #00100B;
  font-weight: 700;
  font-size: 13px;
}

.notify-user-ticket-theme {
  color: #6C6EA0;
  font-weight: 600;
  font-size: 13px;
}

.new-ticket-notify {
  color: #00100B;
  font-weight: 600;
  font-size: 24px;
}

.new-ticket-request {
  font-size: 32px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.tickets-modal-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 5;

  @media (max-width: 768px) {
    height: auto;
  }
}

.addTicketsModal {
  position: absolute;
  background-color: white;
  max-width: 582px;
  min-height: 550px;
  border-radius: 20px;
  z-index: 5;
  padding: 40px 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    position: unset;
    margin-top: 50px;
    padding: 30px;
  }
}

.tickets-btn-cancel {
  width: 100%;
  // max-width: 138px;
  height: 39px;
  background-color: transparent;
  border: 1px solid #8f97a9;
  color: #ff1053;
  border-radius: 8px;

  // @media (min-width: 768px) {
  //   width: 226px;
  // }

  // @media (max-width: 768px) {
  //   width: 226px;
  // }
}

.tickets-btn-create {
  width: 100%;
  // max-width: 200px;
  height: 39px;
  background-color: #ff1053;
  color: white;
  border-radius: 8px;
  border: none;

  // @media (min-width: 768px) {
  //     width: 226px;
  // }
}

.add-tickets-modal-save {
  background-color: #d2d5da;
}

.ticket-menu__title-text {
  @include text($size: 20px, $weight: 500, $color: #000000);

  @media (max-width: 700px) {
    @include text($size: 14px, $weight: 600, $color: #ff1053);
  }
}

.new-ticket-not-mobile {
  width: 140px;
  border-radius: 8px;
}

.tickets-modal-btns {
  gap: 25px;
  align-items: center;
}

.new-ticket-request {
  @include text($size: 32px, $weight: 400, $color: #000000);
  margin: 0;
}

.create-tiocket-modal {
  margin-top: 100px !important;
}

.ticket-cancel-btn {
  width: 100%;
  max-width: 240px;
}

.ticket-send-tickets {
  width: 100% !important;
  max-width: 240px !important;
}

.editInputChanged {
  background-color: #ff1053;
}

.tickets-filters {
  display: flex;
  width: 100%;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.filter-item {
  width: 100%;
}

.tickets-main-calendar {
  padding-left: 10px;
  border-radius: 8px;
  justify-content: space-between;
  padding-right: 10px;
  background-color: #f0f0f0;
}

.tickets-search-wrapper {
  justify-content: flex-start !important;
  padding-top: 14px;
  gap: 8px !important;

  .custom-table__label {
    color: #8F97A9;
    font-size: 13px;
    font-weight: 500;
  }
}

.tickets-custom-select-wrapper {
  height: 44px;
  height: 44px;
  margin-top: -2px;
}

.toggle-filters {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 15px;
}

.toggle-filters-btn {
  width: unset;
  display: none;
  background: none;
  border: none;
  color: #8f97a9;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;

  @media (max-width: 768px) {
    display: block;
  }
}

.toggle-filters-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.user-select-tickets {
  width: 304px !important;
}

.tickets-CalendarIcon {
  cursor: pointer;
}

.tickets-user-status-select {
  width: 304px;
}

.tickets-user-status-wrapper {
  .custom-select__options {
    width: 304px;
  }
}

.tickets-pagination {
  @media (max-width: 768px) {
    font-size: 10px !important;
    font-weight: 400 !important;

    button {
      margin: 0;
    }
  }
}

.tickets-btn--mobile-hidden {
  border-radius: 8px;
}

.tickets-filter-label {
  color: #8F97A9;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}

.tickets-main-user-filter {
  margin-top: -5px;
}

.ticket-calendar-container {
  width: 320px;

  .react-datepicker__month,
  .react-datepicker__month-container {
    width: 320px;
  }
}

.add-advetising-btn-create.add-office-modal-save.close-ticket-modal {
  background-color: #FF1053 !important;
  width: 162px;

  @media (max-width: 768px) {
    display: none;
  }
}

.close-ticket-modal.close-ticket-modal-mbl {
  display: none;

  @media (max-width: 768px) {
    display: block !important;
    width: 100% !important;
    margin-top: 30px;
  }

}

.ticket-modal-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-advetising-btn-create.add-office-modal-save.ticket-send-msg-btn {
  background-color: transparent;
  color: #8F97A9;
  border: 1px solid #8F97A9;
  width: 164px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.send-msg-container-ticket-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: block;
  }
}

.TicketChatClose {
  cursor: pointer;
}

.chat-ticket-num {
  @include text($size: 32px, $weight: 400);
}

.chat-messages-container {
  height: 200px;
  overflow: scroll;
}



.chat-modal-message {
  background-color: #EAF8FF;
  padding: 12px;
  border-radius: 12px;
  width: fit-content;
}

.create-ticket-modal {
  position: initial;
}

.ticket-chat-msg-img {
  display: flex;
  gap: 5px;
  align-items: center;
}

.tkt-chat-message-date {
  margin: 0;
  @include text($size: 13px, $weight: 400, $color: #8F97A9);
  display: flex;
  gap: 2px;

  p {
    margin: 0;
  }
}

.chat-tkt-sender {
  @include text($size: 13px, $weight: 700);

}

.chat-ticket-num {
  margin: 0;
}

.tkt-modal-theme {
  @include text($size: 20px, $weight: 600);
}

.tkt-modal-theme-span {
  @include text($size: 14px, $weight: 400, $color: #37A72E);
  margin-left: 5px;
}

.chat-modal-container-msg {
  display: flex;
  gap: 5px;
  align-items: center;

  p {
    margin: 0;
  }
}

.chat-modal-container-msg-head {
  display: flex;
  gap: 5px;
  align-items: center;

  p {
    margin: 0;
  }
}

.actual-sender {
  justify-content: end;
}

.modal-tkst-messages {
  max-width: 900px !important;
  padding-top: 20px;
  width: 845px;
}

.chat-messages {
  height: 200px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #F0F0F0;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 10px;
}

.chat-message {
  display: flex;
  gap: 10px;
  justify-content: start;
}

.actual-sender-chat {
  justify-content: end;
}

.img-actual-sender,
.not-actual-sender {
  display: none;
}


.message-input {

  .msg-added {
    background-color: #FF1053 !important;
    color: white !important;
  }

}

.delete-file-btn {
  border: none;
  background-color: transparent;
}

.file-info {
  display: flex;
  gap: 10px;
}

.attached-file {
  display: flex;
  gap: 10px;
}

.notify-modal-messages-box {
  position: relative;
  justify-content: start;
  margin: 0;
  width: 800px;
  box-shadow: none;
  margin-top: -70px;
}

.notify-modal-header {
  margin-bottom: 30px;
}

.notify-box-modal-main {
  max-width: none !important;
  width: 100%;
  padding-left: 30px;
  box-shadow: none;
}

.notify-box-modal-main-child {
  box-shadow: none;
  padding-left: 20px;
  padding-top: 10px;

}

.notify-container-main-box {
  display: flex;
}

.notify-chat-messages {
  height: 300px;
}

.all-tickets-btn {
  width: 100%;
  border: 1px solid #8F97A9;
  border-radius: 8px;
  height: 39px;
  background-color: #FFFFFF;
  color: #FF1053;
  @include text($size: 14px, $weight: 600, $text-align: center, $color: #FF1053);
}

.tickets-status-select {
  @media (max-width: 768px) {
    width: 100%;
  }
}