.balance-section-contact {
  background-color: #fbfbfb;
  color: #8f97a9;
}

.z-index-for-messenger {
  z-index: 100;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.contact-details {
  .contact-detail-phone {
    width: 130px;
    padding: 0 8px;
  }
}

.select-box-contact-details {
  height: 44px;
  // background-color: $middleGray;
  display: flex;
  gap: 1px;
  align-items: center;
}

.phone-dropdown {
  z-index: 1000;
}

.max-length-error-users {
  margin-top: 10px;
}