@import "../../../../assets/styles/mixins.scss";

.login-form {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login-input {
    width: calc(100% - 20px); 
    margin-left: 10px; 
    margin-right: 10px;
    height: 54px;
    background-color: #f0f0f0;
    border-radius: 8px;
    color: black;
    border: none;
}

.login-modal-fa {
    gap: 0 !important;
}

.login-form-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form-header {
    @include text($size: 28px, $weight: 600, $line: 1rem, $padding: 0.75rem 1.25rem);
}

.login-form-forget {
    @include text($size: 16px, $weight: 400, $color: #6c6ea0);
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none;
}

.login-text-forget {
    color: #6c6ea0;
    text-align: center;
    margin: 0;
    cursor: pointer;
    font-weight: 600;
}

.login-error {
    text-align: center;
}

.loading-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader {
    font-size: 1.5em;
    color: #333;
}
