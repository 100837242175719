@import '../../../assets/styles/variables.scss';

.date-picker-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $datePickerColor;
    padding: 20px 0;
    border-radius: 10px;
    width: 390px;
    position: absolute;
    z-index: 100;

    .react-datepicker__month {
        width: 390px;
    }

    .react-datepicker {
        width: auto;
        border: none;
        box-shadow: none;

        .react-datepicker__month-container {
            background-color: $defaultWhiteColor;
            color: $datePickerColor;
            border: 1px solid #ddd;
            border-radius: 8px;

            .react-datepicker__header {
                background-color: $defaultWhiteColor;
            }

            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__time-name {
                color: $datePickerColor;
            }

            .react-datepicker__day--selected,
            .react-datepicker__day--in-range,
            .react-datepicker__day--in-selecting-range {
                background-color: #d81b60;
                color: $defaultWhiteColor;
            }

            .react-datepicker__day--keyboard-selected {
                background-color: #d81b60;
                color: #fff;
            }

            .react-datepicker__day:hover {
                background-color: #e91e63;
                color: #fff;
            }

            .react-datepicker__day--outside-month {
                color: $MoonColorGray;
            }

            .react-datepicker__header {
                border-bottom: none !important;
            }
        }
    }
}