@import "../../../../assets/styles/mixins";

.register-input {
    width: 100% !important;
    height: 54px;
    background-color: #f0f0f0;
    border-radius: 8px;
    border: none;
    font-size: 16px;

    &::placeholder {
        color: #8f97a9;
        opacity: 1;
    }
}

.register-messenger-box {
    height: 44px;
    // background-color: $middleGray;
    // gap: 5px;
    display: flex;
    gap: 1px;
    align-items: center;
}

.phone-input {
    width: 100%;
}

.register-select {
    width: 90px;

    .custom-react-select__control {
        height: 52px;
        background-color: #f0f0f0;
        border: none;
        border-radius: 8px;
        // padding: 0 10px;

        @media (max-width: 768px) {
            width: 90px;
        }
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }
}

.register-checkbox {
    cursor: pointer;
}

.checkmark {
    cursor: pointer;
}

.time-zone-select {
    width: 100%;

    .custom-react-select__control {
        height: 52px;
        background-color: #f0f0f0;
        border: none;
        border-radius: 8px;
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }
}

.full-width {
    width: 100%;
}

.phone-select {
    width: 106px;
    display: flex !important;
    justify-content: space-around !important;

    color: hsl(0, 0%, 80%);
    cursor: pointer;

    &:hover {
        color: hsl(0, 0%, 60%);
    }

    @media (min-width: 768px) {
        width: 90px;
    }
}

.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    font-size: 16px;
    user-select: none;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 2px solid #d3d3d3;
    border-radius: 4px;
}

.custom-checkbox input:checked~.checkmark {
    background-color: #ff1053;
    border: none;
}

.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 8px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.register-btn {
    min-width: 96px;
    height: 42px;
    background-color: #ff1053;
    color: white;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 0 10px;
}

.disabled {
    background-color: gray;
    cursor: not-allowed;
}

.register-container {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 768px) {
        padding: 20px;
    }

    @media (min-width: 768px) {
        min-width: 520px;
    }

    &__absolute-layer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.5);
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
    }
}

.register-label {
    color: #8f97a9;
    margin-bottom: 8px;
}

.phone-img {
    width: 90px;
    height: 54px;
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 90px;
        padding: 0 10px;
    }
}

.phone-box {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1px;
}

.select-box {
    display: flex;
    gap: 1px;
    align-items: center;
}

.register-form {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 520px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    box-sizing: border-box;

    form {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.register-input,
.phone-input,
.registration-input {
    // width: 100%;
    height: 54px;
    background-color: #f0f0f0;
    border-radius: 8px;
    color: black;
    border: none;
    padding: 0 1rem;
    box-sizing: border-box;

    input:focus {
        outline: 10px solid #8f97a9 !important;
        border-color: #8f97a9;
    }
}

.registration-container {
    @include flex($direction: row, $justify: center, $align: center);
}

.error-message {
    color: #d80027;
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) {
    .register-form {
        width: 520px;
        height: 100%;
    }
}